
import {
  defineComponent, onBeforeMount, reactive, watch, ref, provide,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useTelaBase } from '@/core/composables/TelaBase';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import SelecionarFiltroPeriodoMovimentoComercial from '@/components/Compartilhados/SelecionarFiltroPeriodoMovimentoComercial.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import storeSistema from '@/store/storeSistema';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { ICardKanban, ICardKanbanDetalhe, IColunaKanban } from '@/core/models/IKanban';
import Kanban from '@/core/components/Kanban/Kanban.vue';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import NotaFiscalModal from './NotaFiscalModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import EnviarEmailPessoaModal from '@/components/Cadastros/Pessoas/EnviarEmailPessoaModal.vue';
import { IRetornoComunicacaoDFe } from '@/core/models/IRetornoComunicacaoDFe';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { EStatusNotaFiscal } from '@/models/Enumeradores/Compartilhados/EStatusNotaFiscal';
import { IDTONotaFiscal } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscal';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import NotaFiscalListagem from '@/components/Fiscal/NotasFiscais/NotaFiscalListagem.vue';
import { IParametrosConsultaNotaFiscal } from '@/models/ParametrosRequisicao/Fiscal/IParametrosConsultaNotaFiscal';
import { IRetornoNotaFiscal } from '@/models/Retornos/Fiscal/IRetornoNotaFiscal';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import { EEmitenteDocumentoFiscal } from '@/models/Enumeradores/Fiscal/EEmitenteDocumentoFiscal';
import { EOrigemNotaFiscal } from '@/models/Enumeradores/Fiscal/EOrigemNotaFiscal';

export default defineComponent({
  name: 'NotaFiscalIndex',
  components: {
    Kanban,
    NotaFiscalModal,
    NotaFiscalListagem,
    SelecionarDataInicialFinal,
    SelecionarFiltroPeriodoMovimentoComercial,
    Icone,
    Card,
    TituloPadrao,
    BuscaGeral,
    EnviarEmailPessoaModal,
    PreviewPdf,
  },
  setup(props) {
    const servicoNotaFiscal = new ServicoNotaFiscal();
    let debounce = 0;
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      adicionarAtalho, removerAtalho, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();

    const dadosEmpresa = ref<IDTODadosEmpresa>({} as IDTODadosEmpresa);
    provide('NOTAS_FISCAIS_EMPRESA', dadosEmpresa);

    const route = useRoute();
    const state = reactive({
      identificadorRecurso: 'NOTAS_FISCAIS',
      identificadorPermissao: 'AUT_NOTAS_FISCAIS',
      empresaSelecionada: 0,
      dataAtual: '',
      filtroPeriodo: 4,
      dataInicialSelecionada: '',
      dataFinalSelecionada: '',
      buscaRapida: '',
      colunas: [] as IColunaKanban[],
      filtros: [] as IFiltroGenerico[],
      selecionados: [] as number[],
      paginacao: {} as IPagination,
      cardSelecionado: '',
      notasFiscais: [] as IDTONotaFiscal[],
      buscaAvancada: {} as IBuscaAvancada,
      exibirLancamento: false,
      operacaoLancamento: {} as ITelaOperacao,
      qtdSelecionados: 0,
      qtdSelecionadosAutorizadas: 0,
      qtdSelecionadosConcluir: 0,
      qtdSelecionadosRetomar: 0,
      qtdSelecionadosCancelar: 0,
      qtdSelecionadosExcluir: 0,
      visualizarKanban: true,
      atualizandoCards: false,
      arquivosPdf: [] as IArquivoPdf[],
      apresentarEnvioEmail: false,
      codigoPessoaEmail: 0,
      notaFiscalEnvioEmail: {} as IDTONotaFiscal,
    });

    function atualizarQtdSelecionados() {
      state.qtdSelecionados = 0;
      state.qtdSelecionadosAutorizadas = 0;
      state.qtdSelecionadosConcluir = 0;
      state.qtdSelecionadosCancelar = 0;
      state.qtdSelecionadosExcluir = 0;
      state.qtdSelecionadosRetomar = 0;
      state.colunas.forEach((c) => {
        if (c.qtdSelecionados !== undefined) {
          state.qtdSelecionados += c.qtdSelecionados;
          if (Number(c.identificador) === EStatusNotaFiscal.Autorizada) {
            state.qtdSelecionadosAutorizadas += c.qtdSelecionados;
          }
        }
      });
    }

    function atualizarSelecionadosKanban() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
      });

      state.notasFiscais.forEach((n) => {
        const indexColuna = state.colunas.findIndex((c) => c.identificador === String(n.status));
        const indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(n.codigo));
        const indexSelecionados = state.selecionados.findIndex((s) => s === n.codigo);
        if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = indexSelecionados >= 0;
      });
      atualizarQtdSelecionados();
    }

    function atualizarSelecionados(identificador: string) {
      const indexSelecionados = state.selecionados.findIndex((s) => s === Number(identificador));
      if (indexSelecionados < 0) {
        state.selecionados.push(Number(identificador));
      } else {
        state.selecionados.splice(indexSelecionados, 1);
      }
      atualizarQtdSelecionados();
    }

    function atualizarSelecionadosEmMassa(identificadoresSelecionados: string[]) {
      identificadoresSelecionados.forEach((identificador) => {
        const indexSelecionados = state.selecionados.findIndex((s) => s === Number(identificador));
        if (indexSelecionados < 0) {
          state.selecionados.push(Number(identificador));
        } else {
          state.selecionados.splice(indexSelecionados, 1);
        }
      });

      atualizarQtdSelecionados();
    }

    function desmarcarSelecionado(codigo: number, status: number, statusNovo: number) {
      const indexSelecionados = state.selecionados.findIndex((s) => s === codigo);
      if (indexSelecionados >= 0) {
        let indexColuna = state.colunas.findIndex((c) => c.identificador === String(status));
        let indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigo));
        if (indexCard === -1) {
          indexColuna = state.colunas.findIndex((c) => c.identificador === String(statusNovo));
          indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigo));
        }
        if (state.colunas[indexColuna].qtdSelecionados > 0) state.colunas[indexColuna].qtdSelecionados -= 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = false;
        state.selecionados.splice(indexSelecionados, 1);
      }
      atualizarQtdSelecionados();
    }

    function preencherColunas() {
      state.colunas = [] as IColunaKanban[];
      state.colunas[0] = {} as IColunaKanban;
      state.colunas[0].identificador = '1';
      state.colunas[0].titulo = 'Pendente';
      state.colunas[0].cor = '#969696';
      state.colunas[0].textoTotalizador = 'Total';
      state.colunas[0].cards = [] as ICardKanban[];

      state.colunas[1] = {} as IColunaKanban;
      state.colunas[1].identificador = '2';
      state.colunas[1].titulo = 'Autorizadas';
      state.colunas[1].cor = '#1843b8';
      state.colunas[1].textoTotalizador = 'Total';
      state.colunas[1].cards = [] as ICardKanban[];

      state.colunas[2] = {} as IColunaKanban;
      state.colunas[2].identificador = '3';
      state.colunas[2].titulo = 'Canceladas';
      state.colunas[2].cor = '#cf2751';
      state.colunas[2].textoTotalizador = 'Total';
      state.colunas[2].cards = [] as ICardKanban[];
    }

    function limparCards() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
        state.colunas[index].cards = [] as ICardKanban[];
      });
    }

    function limparNotaFiscalEnvioEmail() {
      state.codigoPessoaEmail = 0;
      state.notaFiscalEnvioEmail = {} as IDTONotaFiscal;
      state.notaFiscalEnvioEmail.codigo = 0;
      state.notaFiscalEnvioEmail.codigoPessoaDestinatario = 0;
      state.notaFiscalEnvioEmail.numero = 0;
    }

    function criarCard(indexColuna: number, notaFiscal: IDTONotaFiscal) {
      const card = {} as ICardKanban;
      const indexSelecionados = state.selecionados.findIndex((s) => s === notaFiscal.codigo);

      card.identificadorColuna = state.colunas[indexColuna].identificador;
      card.identificador = String(notaFiscal.codigo);
      card.titulo = `Nº : ${notaFiscal.numero}`;
      card.ordem = notaFiscal.ordem;
      card.selecionado = indexSelecionados >= 0;
      card.detalhes = [] as ICardKanbanDetalhe[];

      let indexDetalhe = 0;

      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Origem: ';
      card.detalhes[indexDetalhe].detalhe = servicoNotaFiscal.descricaoOrigemNotaFiscal(notaFiscal.origemNotaFiscal);

      indexDetalhe += 1;

      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Data de Emissão: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscal.dataEmissao);
      indexDetalhe += 1;

      let nomePessoa = '';
      if (notaFiscal.tipoEmissao === EEmitenteDocumentoFiscal.EmissaoPropria) {
        if (UtilitarioGeral.valorValido(notaFiscal.nomeRazaoSocialDestinatario)) {
          nomePessoa = notaFiscal.nomeRazaoSocialDestinatario.length > 29 ? notaFiscal.nomeRazaoSocialDestinatario.substring(0, 30) : notaFiscal.nomeRazaoSocialDestinatario;
        }
      } else if (UtilitarioGeral.valorValido(notaFiscal.nomeRazaoSocialEmitente)) {
        nomePessoa = notaFiscal.nomeRazaoSocialEmitente.length > 29 ? notaFiscal.nomeRazaoSocialEmitente.substring(0, 30) : notaFiscal.nomeRazaoSocialEmitente;
      }

      if (UtilitarioGeral.valorValido(nomePessoa)) {
        card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
        card.detalhes[indexDetalhe].titulo = 'Pessoa: ';
        card.detalhes[indexDetalhe].detalhe = nomePessoa;
        indexDetalhe += 1;
      }

      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Total R$: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioMascara.mascararValor(notaFiscal.totalNotaFiscal, 2);
      card.valor = notaFiscal.totalNotaFiscal;

      state.colunas[indexColuna].cards.push(card);
      if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
    }

    function prencheDatasPeriodo() {
      switch (state.filtroPeriodo) {
        case EFiltroPeriodoMovimentoComercial.Hoje:
          state.dataInicialSelecionada = state.dataAtual;
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ontem:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          state.dataFinalSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          break;

        case EFiltroPeriodoMovimentoComercial.SemanaAtual:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.MesAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.AnoAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos15Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos30Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos60Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos90dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        default:
      }
    }

    async function buscarNotasFiscais(ordenacao: IOrdenacao[] = []) {
      state.notasFiscais = [];
      limparCards();
      state.filtros = [] as IFiltroGenerico[];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        state.filtros.push(item.filtro);
      });

      prencheDatasPeriodo();
      const parametrosConsulta = {} as IParametrosConsultaNotaFiscal;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      if (UtilitarioGeral.validaLista(ordenacao) && !state.visualizarKanban) {
        ordenacao.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });
      } else {
        parametrosConsulta.ordenacao.push('Status', 'Ordem');
      }

      parametrosConsulta.qtdeRegistrosPagina = 1000;

      telaBase.carregando = true;
      const listaPaginada = await servicoNotaFiscal.buscaAvancada(parametrosConsulta, state.filtros);
      state.notasFiscais = listaPaginada.dados;
      state.notasFiscais.forEach((n) => {
        criarCard(state.colunas.findIndex((c) => c.identificador === String(n.status)), n);
      });
      telaBase.carregando = false;
    }

    function recriarColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);

      state.colunas[index].cards = [] as ICardKanban[];
      state.colunas[index].qtdSelecionados = 0;
      state.colunas[index].textoTotalizador = 'Total';
      state.notasFiscais.forEach((n) => {
        if (n.status === Number(identificador)) {
          criarCard(index, n);
        }
      });
      atualizarQtdSelecionados();
    }

    function obterTituloColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);
      return state.colunas[index].titulo;
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.empresaSelecionada);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);

      const dadosEmpresaCache = storeSistema.state.empresasDisponiveis.find((c) => c.codigoEmpresa === storeSistema.getters.codigoEmpresaOperacao());
      if (dadosEmpresaCache !== undefined) {
        dadosEmpresa.value = dadosEmpresaCache;
      }
      await buscarNotasFiscais();
    }

    function selecionarCard(selecionado: string) {
      state.cardSelecionado = selecionado;
    }

    function incluir() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function editar(codigo: number) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigo;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function clickEditar() {
      editar(Number(state.cardSelecionado));
    }

    async function enviarEmailNFe(emails: string []) {
      if (UtilitarioGeral.validaLista(emails)) {
        const retorno:IRetornoComunicacaoDFe = await servicoNotaFiscal.enviarEmail(state.notaFiscalEnvioEmail.codigo, emails);
        if (retorno.sucesso) {
          apresentarMensagemSucesso(retorno.mensagem);
        } else {
          apresentarMensagemAlerta(retorno.mensagem);
        }
      } else {
        apresentarMensagemAlerta('Nenhum e-mail informado para o envio.');
      }
      state.apresentarEnvioEmail = false;
      limparNotaFiscalEnvioEmail();
    }
    async function alterarStatus(notaFiscal: IDTONotaFiscal, novoStatus: string) {
      let retorno = {} as IRetornoNotaFiscal;
      const statusAntigo = String(notaFiscal.status);
      const index = state.notasFiscais.findIndex((n) => n.codigo === notaFiscal.codigo);
      desmarcarSelecionado(state.notasFiscais[index].codigo, state.notasFiscais[index].status, Number(novoStatus));
      retorno = await servicoNotaFiscal.alterarStatus(notaFiscal.codigo, Number(novoStatus));
      if (retorno.status === 1) {
        state.notasFiscais[index].status = Number(novoStatus);
        if (UtilitarioGeral.valorValido(retorno.linkDocumentoFiscal)) {
          state.arquivosPdf = [{ nome: '', link: retorno.linkDocumentoFiscal } as IArquivoPdf];
        }
      } else if (retorno.status === 2) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.visualizarKanban) {
        recriarColuna(novoStatus);
        recriarColuna(statusAntigo);
      } else {
        buscarNotasFiscais();
      }
    }

    function mensagemVerificacaoPadrao(notaFiscal: IDTONotaFiscal): string {
      return `NF-e: ${notaFiscal.numero} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscal.dataEmissao)}.`;
    }

    async function moverCard(card: ICardKanban, identificadorNovaColuna: string) {
      state.cardSelecionado = card.identificador;
      const indexnotaFiscal = state.notasFiscais.findIndex((n) => n.codigo === Number(state.cardSelecionado));
      const notaFiscal = state.notasFiscais[indexnotaFiscal];

      Modal.confirm({
        title: `Realmente deseja atualizar o Status de: ${obterTituloColuna(String(notaFiscal.status))} para o Status: ${obterTituloColuna(identificadorNovaColuna)}?`,
        content: mensagemVerificacaoPadrao(notaFiscal),
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          await alterarStatus(notaFiscal, identificadorNovaColuna);
        },
        onCancel: () => {
          recriarColuna(identificadorNovaColuna);
          recriarColuna(String(notaFiscal.status));
        },
      });
    }

    async function retomarEtapa() {
      let notaFiscal = {} as IDTONotaFiscal;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosRetomar === 0) {
        apresentarMensagemAlerta('Nenhuma nota fiscal selecionada para retomada de Status.');
        return;
      } if (state.qtdSelecionadosRetomar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.notasFiscais.findIndex((r) => r.codigo === s);
          if (state.notasFiscais[index].status > 1 && state.notasFiscais[index].status < 3) {
            notaFiscal = state.notasFiscais[index];
          }
        });
        titulo = `Realmente deseja atualizar o Status de: ${obterTituloColuna(String(notaFiscal.status))} para ${obterTituloColuna(String(notaFiscal.status - 1))}?`;
        mensagem = mensagemVerificacaoPadrao(notaFiscal);
      } else {
        titulo = `Realmente deseja alterar o Status: das ${state.qtdSelecionadosConcluir} notas fiscais selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosRetomar === 1) {
            await alterarStatus(notaFiscal, String(notaFiscal.status - 1));
          } else {
            const notasFiscais = [] as IDTONotaFiscal[];
            state.selecionados.forEach((s) => {
              const indexnotaFiscal = state.notasFiscais.findIndex((r) => r.codigo === Number(s));
              if (state.notasFiscais[indexnotaFiscal].status > 1 && state.notasFiscais[indexnotaFiscal].status < 3) {
                notasFiscais.push(state.notasFiscais[indexnotaFiscal]);
              }
            });
          }
        },
      });
    }

    async function cancelar() {
      let notaFiscal = {} as IDTONotaFiscal;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosCancelar === 0) {
        apresentarMensagemAlerta('Nenhuma nota fiscal selecionada para o cancelamento.');
        return;
      } if (state.qtdSelecionadosCancelar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.notasFiscais.findIndex((n) => n.codigo === s);
          if (state.notasFiscais[index].status < 3) {
            notaFiscal = state.notasFiscais[index];
          }
        });
        titulo = 'Realmente deseja cancelar a nota fiscal ?';
        mensagem = mensagemVerificacaoPadrao(notaFiscal);
      } else {
        titulo = `Realmente deseja cancelar as ${state.qtdSelecionadosConcluir} notas fiscais selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosCancelar === 1) {
            await alterarStatus(notaFiscal, '3');
          } else {
            const notasFiscais = [] as IDTONotaFiscal[];
            state.selecionados.forEach((s) => {
              const indexnotaFiscal = state.notasFiscais.findIndex((n) => n.codigo === Number(s));
              if (state.notasFiscais[indexnotaFiscal].status < 3) {
                notasFiscais.push(state.notasFiscais[indexnotaFiscal]);
              }
            });
          }
        },
      });
    }

    async function excluirnotasFiscais(notaFiscal: IDTONotaFiscal[]) {
      const colunasAlteradas = [] as string[];
      const codigosnotasFiscais = [] as number[];

      notaFiscal.forEach((notaFiscal) => {
        desmarcarSelecionado(notaFiscal.codigo, notaFiscal.status, notaFiscal.status);
        if (!colunasAlteradas.some((c) => c === String(notaFiscal.status))) {
          colunasAlteradas.push(String(notaFiscal.status));
        }
        codigosnotasFiscais.push(notaFiscal.codigo);
      });

      const retorno = await servicoNotaFiscal.excluirVarios(state.empresaSelecionada, codigosnotasFiscais);
      if (retorno.status === 1) {
        notaFiscal.forEach((notaFiscal) => {
          const indexnotaFiscal = state.notasFiscais.findIndex((n) => n.codigo === notaFiscal.codigo);
          state.notasFiscais.splice(indexnotaFiscal, 1);
        });
        colunasAlteradas.forEach((c) => {
          recriarColuna(c);
        });
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function excluir() {
      const notasFiscais = [] as IDTONotaFiscal[];
      let notaFiscal = {} as IDTONotaFiscal;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosExcluir === 0) {
        apresentarMensagemAlerta('Nenhuma nota fiscal selecionada para exclusão.');
        return;
      } if (state.qtdSelecionadosExcluir === 1) {
        state.selecionados.forEach((s) => {
          const index = state.notasFiscais.findIndex((n) => n.codigo === s);
          if (state.notasFiscais[index].status === 1) {
            notaFiscal = state.notasFiscais[index];
          }
        });
        titulo = 'Realmente deseja excluir a nota fiscal ?';
        mensagem = `Nota ${notaFiscal.numero} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscal.dataEmissao)}.`;
      } else {
        titulo = `Realmente deseja excluir as ${state.qtdSelecionadosExcluir} notas fiscais selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosExcluir === 1) {
            notasFiscais.push(notaFiscal);
            await excluirnotasFiscais(notasFiscais);
          } else {
            state.selecionados.forEach((s) => {
              const indexnotaFiscal = state.notasFiscais.findIndex((r) => r.codigo === Number(s));
              if (state.notasFiscais[indexnotaFiscal].status === 1) {
                notasFiscais.push(state.notasFiscais[indexnotaFiscal]);
              }
            });
            await excluirnotasFiscais(notasFiscais);
          }
        },
      });
    }

    function verificaVisualizarDFe(codigo: string): boolean {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscal = state.notasFiscais.find((c) => c.codigo === Number(codigo));
        if (notaFiscal !== undefined) {
          return (notaFiscal.tipoEmissao === EEmitenteDocumentoFiscal.EmissaoPropria && UtilitarioGeral.valorValido(notaFiscal.identificador));
        }
      }
      return false;
    }

    function verificaEmissao(codigo: string): boolean {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscal = state.notasFiscais.find((c) => c.codigo === Number(codigo));
        if (notaFiscal !== undefined) {
          return (notaFiscal.tipoEmissao === EEmitenteDocumentoFiscal.EmissaoPropria && notaFiscal.origemNotaFiscal === EOrigemNotaFiscal.EmissaoNotaFiscalEletronica && notaFiscal.status === EStatusNotaFiscal.Pendente && notaFiscal.totalNotaFiscal > 0);
        }
      }
      return false;
    }

    async function visualizarDFe(codigo: string) {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscal = state.notasFiscais.find((c) => c.codigo === Number(codigo));
        if (notaFiscal !== undefined) {
          const retorno = await servicoNotaFiscal.visualizar(notaFiscal.codigo);
          if (retorno !== undefined) {
            if (retorno.sucesso) {
              if (UtilitarioGeral.valorValido(retorno.linkPdf)) {
                state.arquivosPdf = [{ nome: '', link: retorno.linkPdf } as IArquivoPdf];
              }
            } else if (UtilitarioGeral.valorValido(retorno.mensagem)) {
              apresentarMensagemAlerta(retorno.mensagem);
            }
          }
        }
      }
    }

    function apresentarEnvioEmail(codigo: string) {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscal = state.notasFiscais.find((c) => c.codigo === Number(codigo));
        if (notaFiscal !== undefined) {
          if (UtilitarioGeral.valorValido(notaFiscal.identificador)) {
            state.notaFiscalEnvioEmail = notaFiscal;
            state.codigoPessoaEmail = notaFiscal.codigoPessoaDestinatario;
            state.apresentarEnvioEmail = true;
          } else {
            state.apresentarEnvioEmail = false;
          }
        }
      }
    }

    function obtemIdentificadoresAutorizados(): string[] {
      const identificadoresAutorizados:string[] = [];
      if (UtilitarioGeral.validaLista(state.selecionados)) {
        state.selecionados.forEach((codigo) => {
          const notaFiscal = state.notasFiscais.find((c) => c.codigo === Number(codigo));
          if (notaFiscal !== undefined) {
            if (notaFiscal.status === EStatusNotaFiscal.Autorizada) {
              identificadoresAutorizados.push(notaFiscal.identificador);
            }
          }
        });
      }
      return identificadoresAutorizados;
    }

    async function baixarPdfs() {
      const identificadoresAutorizados = obtemIdentificadoresAutorizados();
      if (UtilitarioGeral.validaLista(identificadoresAutorizados)) {
        const retorno = await servicoNotaFiscal.downloadPdfs(state.empresaSelecionada, identificadoresAutorizados);
        if (retorno !== undefined) {
          if (retorno.sucesso) {
            if (UtilitarioGeral.valorValido(retorno.link)) {
              UtilitarioGeral.downloadArquivo(retorno.link);
              return;
            }
          }

          if (UtilitarioGeral.valorValido(retorno.mensagem)) {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      } else {
        apresentarMensagemAlerta('Não identificamos nenhuma NF-e autorizada.');
      }
    }

    async function baixarXmls() {
      const identificadoresAutorizados = obtemIdentificadoresAutorizados();
      if (UtilitarioGeral.validaLista(identificadoresAutorizados)) {
        const retorno = await servicoNotaFiscal.downloadXmls(state.empresaSelecionada, identificadoresAutorizados);
        if (retorno !== undefined) {
          if (retorno.sucesso) {
            if (UtilitarioGeral.valorValido(retorno.link)) {
              UtilitarioGeral.downloadArquivo(retorno.link);
              return;
            }
          }

          if (UtilitarioGeral.valorValido(retorno.mensagem)) {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      } else {
        apresentarMensagemAlerta('Não identificamos nenhuma NF-e autorizada.');
      }
    }

    async function estrategiaParametroRota() {
      const visualizarNFe = String(route.query.visualizarNFe);
      if (UtilitarioGeral.validaCodigo(visualizarNFe)) {
        const retorno = await servicoNotaFiscal.visualizar(Number(visualizarNFe));
        if (retorno !== undefined) {
          if (retorno.sucesso) {
            if (UtilitarioGeral.valorValido(retorno.linkPdf)) {
              state.arquivosPdf = [{ nome: '', link: retorno.linkPdf } as IArquivoPdf];
            }
          } else if (UtilitarioGeral.valorValido(retorno.mensagem)) {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      } else {
        const editarCodigonotaFiscal = String(route.query.editar);
        if (UtilitarioGeral.validaCodigo(editarCodigonotaFiscal)) {
          editar(Number(editarCodigonotaFiscal));
        }
      }
    }

    // Monitorar mudanças na rota
    watch(() => route.fullPath, () => {
      estrategiaParametroRota();
    });

    onBeforeMount(async () => {
      state.visualizarKanban = !UtilitarioDispositivo.larguraTelaMobile();
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      telaBase.identificadorRecurso = state.identificadorRecurso;
      telaBase.identificadorPermissao = state.identificadorPermissao;
      preencherColunas();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([state.empresaSelecionada]);
      telaBase.propriedadesConsulta = await servicoNotaFiscal.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      state.dataAtual = await new ServicoSistema().obterDataAtual();

      const dadosEmpresaCache = storeSistema.state.empresasDisponiveis.find((c) => c.codigoEmpresa === storeSistema.getters.codigoEmpresaOperacao());
      if (dadosEmpresaCache !== undefined) {
        dadosEmpresa.value = dadosEmpresaCache;
      }
      await buscarNotasFiscais();
      await estrategiaParametroRota();
      limparNotaFiscalEnvioEmail();
    });

    function buscaRapidaDevolucao() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarNotasFiscais();
      }, 600);
    }

    async function periodoAlterado() {
      if (state.filtroPeriodo !== EFiltroPeriodoMovimentoComercial.Personalizado) {
        await buscarNotasFiscais();
      }
    }

    function textoCodigosSelecionados():string {
      if (state.qtdSelecionados === 1) { return '1 - Nota selecionada'; }

      if (state.qtdSelecionados > 1) { return `${state.qtdSelecionados} - Notas selecionadas`; }

      return '';
    }

    return {
      state,
      props,
      window,
      buscaRapidaDevolucao,
      EVinculoEmpresa,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioGeral,
      storeSistema,
      telaBase,
      incluir,
      editar,
      clickEditar,
      excluir,
      retomarEtapa,
      buscarNotasFiscais,
      periodoAlterado,
      prencheDatasPeriodo,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      atualizarSelecionadosEmMassa,
      selecionarCard,
      moverCard,
      atualizarSelecionados,
      EFiltroPeriodoMovimentoComercial,
      atualizarSelecionadosKanban,
      cancelar,
      verificaVisualizarDFe,
      visualizarDFe,
      apresentarEnvioEmail,
      enviarEmailNFe,
      verificaEmissao,
      textoCodigosSelecionados,
      baixarPdfs,
      baixarXmls,
    };
  },
});
