import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IArquivoFiscal } from '@/models/Entidades/Fiscal/IArquivoFiscal';
import { IContabilista } from '@/models/Entidades/Fiscal/IContabilista';
import { ETipoArquivoFiscal } from '@/models/Enumeradores/Fiscal/ETipoArquivoFiscal';
import { IParametrosConsultaEscrituracaoDigital } from '@/models/ParametrosRequisicao/Fiscal/IParametrosConsultaEscrituracaoDigital';
import { IRetornoEFD } from '@/models/Retornos/Fiscal/IRetornoEFD';
/**
 * Serviço de Escrituração Digital
 * Fornece todas regras de negócios e lógicas relacionado a sintegra, SPED fiscal e Pis Cofins.
 */

class ServicoEscrituracaoDigital {
    endPoint = 'escrituracao-digital';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async incluirContabilista(contabilista: IContabilista): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/contabilista`, contabilista);
      return result.data;
    }

    public async alterarContabilista(contabilista: IContabilista): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/contabilista/${contabilista.codigo}`, contabilista);
      return result.data;
    }

    public async obterContabilistaPorEmpresa(codigoEmpresa: number): Promise<IContabilista> {
      const result: any = await this.apiERP.get(`${this.endPoint}/contabilista/empresa/${codigoEmpresa}`);
      return result.data;
    }

    public async obterArquivoFiscal(codigoArquivoFiscal: number): Promise<IArquivoFiscal> {
      const result: any = await this.apiERP.get(`${this.endPoint}/arquivo-fiscal/${codigoArquivoFiscal}`);
      return result.data;
    }

    public async obterArquivosFiscais(empresa: number, tipo: ETipoArquivoFiscal): Promise<IArquivoFiscal[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/arquivo-fiscal/empresa/${empresa}/tipo/${tipo}`);
      return result.data;
    }

    public async gerarSpedFiscal(parametros: IParametrosConsultaEscrituracaoDigital): Promise<IRetornoEFD> {
      const result: any = await this.apiERP.post(`${this.endPoint}/sped/fiscal`, parametros);
      return result.data;
    }

    public async gerarSpedPisCofins(parametros: IParametrosConsultaEscrituracaoDigital): Promise<IRetornoEFD> {
      const result: any = await this.apiERP.post(`${this.endPoint}/sped/pis-cofins`, parametros);
      return result.data;
    }
}
export default ServicoEscrituracaoDigital;
