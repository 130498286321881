
import { computed, defineComponent, reactive } from 'vue';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoCpf from '@/core/components/Tela/CampoCpf.vue';
import CampoCnpj from '@/core/components/Tela/CampoCnpj.vue';
import CampoCep from '@/core/components/Tela/CampoCep.vue';
import SelecionarCidade from '@/components/Cadastros/Localizacoes/Cidades/SelecionarCidade.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IContabilista } from '@/models/Entidades/Fiscal/IContabilista';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import ServicoEscrituracaoDigital from '@/servicos/Fiscal/ServicoEscrituracaoDigital';
import { useTelaBase } from '@/core/composables/TelaBase';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'DadosContabilista',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    contabilista: {
      type: Object as () => IContabilista,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoTelefone,
    CampoCpf,
    CampoCnpj,
    CampoCep,
    SelecionarCidade,
  },
  emits: ['update:contabilista'],
  setup(props, { emit }) {
    const servicoUtilitario = new ServicoUtilitario();
    const servicoCidade = new ServicoCidade();
    const servicoEscrituracaoDigital = new ServicoEscrituracaoDigital();
    const state = reactive({
      salvandoInformacoes: false,
    });

    const { apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();

    const computedContabilista = computed({
      get: () => props.contabilista,
      set: (valor: IContabilista) => {
        emit('update:contabilista', valor);
      },
    });

    async function pesquisarCidade(nomeCidade: string, uf: string) {
      computedContabilista.value.endereco.codigoCidade = 0;
      const cidade = await servicoCidade.obterCidadePorNomeUf(nomeCidade, uf);
      if (UtilitarioGeral.objetoValido(cidade)) {
        if (UtilitarioGeral.validaCodigo(cidade.codigo)) {
          computedContabilista.value.endereco.codigoCidade = cidade.codigo;
        }
      }
    }

    async function consultaCEP(cep:string) {
      const retornoConsultaCEP = await servicoUtilitario.consultaCEP(cep);
      if (UtilitarioGeral.objetoValido(retornoConsultaCEP)) {
        if (UtilitarioGeral.valorValido(retornoConsultaCEP.cidade)) {
          computedContabilista.value.endereco.logradouro = retornoConsultaCEP.logradouro;
          computedContabilista.value.endereco.bairro = retornoConsultaCEP.bairro;
          if (UtilitarioGeral.valorValido(retornoConsultaCEP.complemento)) {
            computedContabilista.value.endereco.complemento = retornoConsultaCEP.complemento;
          }
          await pesquisarCidade(retornoConsultaCEP.cidade, retornoConsultaCEP.uf);
        }
      }
    }

    async function salvar() {
      if (!UtilitarioGeral.valorValido(computedContabilista.value.cpfCnpj)) {
        apresentarMensagemAlerta('É necessário informar o CPF do contador!');
        return;
      }

      if (!UtilitarioGeral.valorValido(computedContabilista.value.nome)) {
        apresentarMensagemAlerta('É necessário informar o Nome do contador!');
        return;
      }

      if (!UtilitarioGeral.valorValido(computedContabilista.value.endereco.cep)) {
        apresentarMensagemAlerta('É necessário informar o CEP do endereço do contador!');
        return;
      }

      if (!UtilitarioGeral.valorValido(computedContabilista.value.endereco.logradouro)) {
        apresentarMensagemAlerta('É necessário informar o endereço do contador!');
        return;
      }

      if (!UtilitarioGeral.valorValido(computedContabilista.value.endereco.bairro)) {
        apresentarMensagemAlerta('É necessário informar o bairro do endereço do contador!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(computedContabilista.value.endereco.codigoCidade)) {
        apresentarMensagemAlerta('É necessário informar a cidade endereço do contador!');
        return;
      }
      computedContabilista.value.codigoEmpresa = props.empresa;
      state.salvandoInformacoes = true;
      let retorno:IRetornoRequisicao;
      if (computedContabilista.value.codigo > 0) {
        retorno = await servicoEscrituracaoDigital.alterarContabilista(computedContabilista.value);
      } else {
        retorno = await servicoEscrituracaoDigital.incluirContabilista(computedContabilista.value);
      }
      state.salvandoInformacoes = false;
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }
    return {
      computedContabilista,
      state,
      consultaCEP,
      salvar,
    };
  },
});
