export enum ESituacaoDocumentoFiscal {
  DocumentoRegular = 0,
  EscrituracaoExtemporaneaRegular = 1,
  DocumentoCancelado = 2,
  EscrituracaoExtemporaneaCancelado = 3,
  DocumentoDenegado = 4,
  NumeracaoInutilizada = 5,
  DocumentoComplementar = 6,
  EscrituracaoExtemporaneaComplementar = 7,
  DocumentoRegimeEspecial = 8,
}
