
import {
  computed, defineComponent, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCstIcms from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCstIcms.vue';
import SelecionarCstIpi from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCstIpi.vue';
import SelecionarCstPis from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCstPis.vue';
import SelecionarCstCofins from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCstCofins.vue';
import SelecionarOrigemMercadoria from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarOrigemMercadoria.vue';
import SelecionarModalidadeBaseCalculoIcms from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarModalidadeBaseCalculoIcms.vue';
import SelecionarModalidadeBaseCalculoIcmsSubstituicao from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarModalidadeBaseCalculoIcmsSubstituicao.vue';
import SelecionarCodigoEnquadramentoIpi from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCodigoEnquadramentoIpi.vue';
import SelecionarTipoCalculoImposto from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarTipoCalculoImposto.vue';
import SelecionarCest from '@/components/Cadastros/Produtos/SelecionarCest.vue';
import SelecionarCsosn from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCsosn.vue';
import { INotaFiscalItemImposto } from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'NotaFiscalItemImpostos',
  props: {
    impostos: {
      type: Object as () => INotaFiscalItemImposto,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    atualizarApenasInformacoesFiscais: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Card,
    CampoNumerico,
    SelecionarCstIcms,
    SelecionarCstIpi,
    SelecionarCstPis,
    SelecionarCstCofins,
    SelecionarOrigemMercadoria,
    SelecionarModalidadeBaseCalculoIcms,
    SelecionarModalidadeBaseCalculoIcmsSubstituicao,
    SelecionarCodigoEnquadramentoIpi,
    SelecionarTipoCalculoImposto,
    SelecionarCest,
    SelecionarCsosn,
  },
  emits: ['update:impostos', 'calcular', 'calcularTributacoes'],
  setup(props, { emit }) {
    const state = reactive({
      definirCst: true,
    });

    function obtemImpostos() {
      state.definirCst = UtilitarioGeral.valorValido(props.impostos.cst);
      return props.impostos;
    }
    const computedImpostos = computed({
      get: () => obtemImpostos(),
      set: (val: INotaFiscalItemImposto) => {
        emit('update:impostos', val);
      },
    });

    function atualizarTotalImpostos() {
      computedImpostos.value.valorTotalTributos = 0;
      computedImpostos.value.valorTotalTributos += computedImpostos.value.icms.valorIcms;
      computedImpostos.value.valorTotalTributos += computedImpostos.value.ipi.valorIpi;
      computedImpostos.value.valorTotalTributos += computedImpostos.value.pis.valorPis;
      computedImpostos.value.valorTotalTributos += computedImpostos.value.cofins.valorCofins;
      computedImpostos.value.valorTotalTributos += computedImpostos.value.importacao.valorImportacao;
    }

    function calcular() {
      if (props.atualizarApenasInformacoesFiscais) return;
      emit('calcular');
    }
    function calcularTributacoes() {
      if (props.atualizarApenasInformacoesFiscais) return;
      emit('calcularTributacoes');
    }

    function definePreenchimentoCstCsosn() {
      if (state.definirCst) {
        computedImpostos.value.cson = '';
      } else {
        computedImpostos.value.cst = '';
      }
    }

    function verificaEdicaoCampoEInformacaoFiscal():boolean {
      if (props.atualizarApenasInformacoesFiscais) {
        return false;
      }

      return !props.editavel;
    }
    return {
      props,
      state,
      computedImpostos,
      atualizarTotalImpostos,
      calcular,
      calcularTributacoes,
      definePreenchimentoCstCsosn,
      verificaEdicaoCampoEInformacaoFiscal,
    };
  },
});
