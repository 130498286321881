
import {
  computed, defineComponent, ref,
} from 'vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoCaracteristica from '@/servicos/Cadastros/Produtos/ServicoCaracteristica';

export default defineComponent({
  name: 'SelecionarCaracteristica',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    omitirCaracteriticas: {
      type: Array as () => number[],
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'change'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    const refSelecionarCaracteristica = ref<HTMLElement | null>(null);

    let debounce = 0;
    const servicoCaracteristica = new ServicoCaracteristica();
    servicoCaracteristica.requisicaoSistema();

    function preencheListaOpcoes():void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        if (props.omitirCaracteriticas !== undefined) {
          props.omitirCaracteriticas.forEach((codigoCaracteristica) => {
            selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((c) => c.codigo !== codigoCaracteristica);
          });
        }
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
    }

    async function obterCaracteristicaPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      selecionarBase.listaItensConsultaRapida = await servicoCaracteristica.consultaRapida(parametrosConsultaRapida);
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarCaracteristica(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;
      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        selecionarBase.listaItensConsultaRapida = await servicoCaracteristica.consultaRapida(parametrosConsultaRapida);
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    function obterCaracteristica():any {
      obterCaracteristicaPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterCaracteristica(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const itemConsulta = selecionarBase.listaItensConsultaRapida.find((c) => c.codigo === val);
        if (itemConsulta?.textoIdentificacao !== undefined && itemConsulta?.textoIdentificacao !== '') {
          computedDescricaoSelecionado.value = itemConsulta?.textoIdentificacao;
        }
      },
    });

    function obterCaracteriticas():number[] {
      obterCaracteristicaPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCaracteriticas(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionarCaracteristica.value) {
        refSelecionarCaracteristica.value.focus();
      }
    }

    return {
      props,
      selecionarBase,
      refSelecionarCaracteristica,
      pesquisarCaracteristica,
      aguardarConclusaoCarregamento,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedDescricaoSelecionado,
      change,
      focus,
      UtilitarioGeral,
    };
  },
});
