import { RouteRecordRaw } from 'vue-router';

const rotasModuloFiscal: Array<RouteRecordRaw> = [
  {
    path: '/fiscal',
    name: 'ModuloFiscal',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'notas-fiscais',
        name: 'NotasFiscais',
        meta: {
          titulo: 'Notas Fiscais de Produto',
          identificadorRecurso: 'NOTAS_FISCAIS',
          identificadorPermissao: 'AUT_NOTAS_FISCAIS',
        },
        component: () => import(/* webpackChunkName: "fiscal" */ '@/views/Fiscal/NotasFiscais/NotaFiscalIndex.vue'),
      },
      {
        path: 'notas-fiscais-servico',
        name: 'NotasFiscaisServico',
        meta: {
          titulo: 'Notas Fiscais de Serviço',
          identificadorRecurso: 'NOTAS_FISCAIS_SERVICO',
          identificadorPermissao: 'AUT_NOTAS_FISCAIS_SERVICO',
        },
        component: () => import(/* webpackChunkName: "fiscal" */ '@/views/Fiscal/NotasFiscaisServicos/NotaFiscalServicoIndex.vue'),
      },
      {
        path: 'arquivos/sped/ecf',
        name: 'SpedEscrituracaoContabilFiscal',
        meta: {
          titulo: 'SPED - Escrituração Contábil Fiscal',
          identificadorRecurso: 'SPED_ECF',
          identificadorPermissao: 'AUT_SPED_ECF',
        },
        component: () => import(/* webpackChunkName: "fiscal" */ '@/core/views/Avisos/RecursoEmBreve.vue'),
      },
      {
        path: 'arquivos/sped/efd-contribuicoes',
        name: 'SpedEfdContribuicoes',
        meta: {
          titulo: 'SPED - EFD Contribuições',
          identificadorRecurso: 'SPED_EFD_CONTRIBUICOES',
          identificadorPermissao: 'AUT_SPED_EFD_CONTRIBUICOES',
        },
        component: () => import(/* webpackChunkName: "fiscal" */ '@/views/Fiscal/EscrituracaoDigital/GerarArquivoSpedContribuicoesIndex.vue'),
      },
      {
        path: 'arquivos/sped/efd-icms-ipi',
        name: 'SpedEfdIcmsIpi',
        meta: {
          titulo: 'SPED - EFD Icms/IPI',
          identificadorRecurso: 'SPED_EFD_ICMS_IPI',
          identificadorPermissao: 'AUT_SPED_EFD_ICMS_IPI',
        },
        component: () => import(/* webpackChunkName: "fiscal" */ '@/views/Fiscal/EscrituracaoDigital/GerarArquivoSpedFiscalIndex.vue'),
      },
      {
        path: 'arquivos/sintegra',
        name: 'Sintegra',
        meta: {
          titulo: 'Sintegra',
          identificadorRecurso: 'SINTEGRA',
          identificadorPermissao: 'AUT_SINTEGRA',
        },
        component: () => import(/* webpackChunkName: "fiscal" */ '@/core/views/Avisos/RecursoEmBreve.vue'),
      },
    ],
  },
];

export default rotasModuloFiscal;
