
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Card from '@/core/components/Tela/Card.vue';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import ServicoEscrituracaoDigital from '@/servicos/Fiscal/ServicoEscrituracaoDigital';
import { IParametrosConsultaEscrituracaoDigital } from '@/models/ParametrosRequisicao/Fiscal/IParametrosConsultaEscrituracaoDigital';
import { IArquivoFiscal } from '@/models/Entidades/Fiscal/IArquivoFiscal';
import { IContabilista } from '@/models/Entidades/Fiscal/IContabilista';
import { IEndereco } from '@/models/Entidades/Cadastros/Localizacoes/IEndereco';
import { ECodigoFinalidadeArquivo } from '@/models/Enumeradores/Fiscal/ECodigoFinalidadeArquivo';
import { EStatusArquivoFiscal } from '@/models/Enumeradores/Fiscal/EStatusArquivoFiscal';
import SelecionarFinalidadeArquivo from '@/components/Fiscal/EscrituracaoDigital/SelecionarFinalidadeArquivo.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import DadosContabilista from '@/components/Fiscal/EscrituracaoDigital/DadosContabilista.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoArquivoFiscal } from '@/models/Enumeradores/Fiscal/ETipoArquivoFiscal';

export default defineComponent({
  name: 'GerarArquivoSpedFiscalIndex',
  components: {
    TituloPadrao,
    Card,
    SelecionarFinalidadeArquivo,
    SelecionarDataInicialFinal,
    DadosContabilista,
  },
  setup(props) {
    const servicoEscrituracaoDigital = new ServicoEscrituracaoDigital();

    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasComPermissao, apresentarMensagemAlerta, apresentarMensagemSucesso,
      adicionarAtalho, removerAtalho, preencherDadosRota,
    } = useTelaBase();

    const route = useRoute();

    const state = reactive({
      identificadorRecurso: 'SPED_EFD_CONTRIBUICOES',
      identificadorPermissao: 'AUT_SPED_EFD_CONTRIBUICOES',
      codigoEmpresa: storeSistema.getters.codigoEmpresaOperacao(),
      abaSelecionada: 1,
      gerandoArquivo: false,
      apresentarDados: false,
      parametrosEscrituracaoDigital: {} as IParametrosConsultaEscrituracaoDigital,
      contabilista: {} as IContabilista,
      arquivosFiscais: [] as IArquivoFiscal[],
      arquivoVisualizacao: {} as IArquivoFiscal,
    });

    telaBase.apresentarEmpresas = false;

    function limparTela() {
      state.apresentarDados = false;
      state.abaSelecionada = 1;
      state.arquivosFiscais = [];
      state.arquivoVisualizacao = {} as IArquivoFiscal;
      state.arquivoVisualizacao.codigo = 0;

      state.parametrosEscrituracaoDigital.empresas = [storeSistema.getters.codigoEmpresaOperacao()];
      state.parametrosEscrituracaoDigital.dataInicial = '';
      state.parametrosEscrituracaoDigital.dataFinal = '';
      state.parametrosEscrituracaoDigital.codigoFinalidade = ECodigoFinalidadeArquivo.ArquivoOriginal;
      state.parametrosEscrituracaoDigital.perfil = '';

      state.contabilista.codigo = 0;
      state.contabilista.codigoEmpresa = 0;
      state.contabilista.codigoEndereco = 0;
      state.contabilista.endereco = {} as IEndereco;
      state.contabilista.endereco.codigo = 0;
      state.contabilista.endereco.cep = '00000000';
      state.apresentarDados = true;
    }

    async function estrategiaParametroRota() {
      const visualizar = String(route.query.visualizar);
      if (UtilitarioGeral.validaCodigo(visualizar)) {
        const arquivoFiscal = await servicoEscrituracaoDigital.obterArquivoFiscal(Number(visualizar));
        if (arquivoFiscal !== undefined) {
          if (arquivoFiscal.codigo > 0) {
            const index = state.arquivosFiscais.findIndex((c) => c.codigo === arquivoFiscal.codigo);
            if (index >= 0) {
              state.arquivosFiscais[index] = arquivoFiscal;
            } else if (arquivoFiscal.status !== EStatusArquivoFiscal.Processando) {
              state.arquivosFiscais.unshift(arquivoFiscal);
            }
            state.arquivoVisualizacao = arquivoFiscal;
            state.abaSelecionada = 3;
          } else {
            apresentarMensagemAlerta('Arquivo não localizado ou não está mais disponível.');
          }
        }
      }
    }

    async function gerarNovoArquivo() {
      limparTela();
      state.arquivosFiscais = await servicoEscrituracaoDigital.obterArquivosFiscais(storeSistema.getters.codigoEmpresaOperacao(), ETipoArquivoFiscal.SpedPisCofins);
    }

    // Monitorar mudanças na rota
    watch(() => route.fullPath, () => {
      estrategiaParametroRota();
    });

    onBeforeMount(async () => {
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      verificaConceitoParaApresentarEmpresas();

      limparTela();
      state.contabilista = await servicoEscrituracaoDigital.obterContabilistaPorEmpresa(storeSistema.getters.codigoEmpresaOperacao());
      if (UtilitarioGeral.validaCodigo(state.contabilista.codigo)) {
        state.abaSelecionada = 2;
      }
      state.arquivosFiscais = await servicoEscrituracaoDigital.obterArquivosFiscais(storeSistema.getters.codigoEmpresaOperacao(), ETipoArquivoFiscal.SpedPisCofins);
    });

    async function gerarArquivo() {
      if (!UtilitarioGeral.valorValido(state.parametrosEscrituracaoDigital.dataInicial)) {
        apresentarMensagemAlerta('Data Inicial não foi informada!');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.parametrosEscrituracaoDigital.dataFinal)) {
        apresentarMensagemAlerta('Data Final não foi informada!');
        return;
      }

      const dataInicial = UtilitarioData.converterDataParaDayJs(state.parametrosEscrituracaoDigital.dataInicial);
      const dataFinal = UtilitarioData.converterDataParaDayJs(state.parametrosEscrituracaoDigital.dataFinal);

      if (dataInicial > dataFinal) {
        apresentarMensagemAlerta('Data Inicial não pode ser superior a data final!');
        return;
      }

      if (dataFinal < dataInicial) {
        apresentarMensagemAlerta('Data Final não pode ser inferior a data incial!');
        return;
      }
      state.gerandoArquivo = true;
      const retornoEfd = await servicoEscrituracaoDigital.gerarSpedPisCofins(state.parametrosEscrituracaoDigital);
      state.gerandoArquivo = false;
      if (retornoEfd.status === EStatusRetornoRequisicao.Sucesso) {
        if (retornoEfd.arquivoFiscal.status === EStatusArquivoFiscal.Processando) {
          apresentarMensagemSucesso(retornoEfd.mensagem);
          state.arquivosFiscais.unshift(retornoEfd.arquivoFiscal);
          state.abaSelecionada = 3;
        }
      } else {
        apresentarMensagemAlerta(retornoEfd.mensagem);
      }
    }

    function baixarArquivo(link:string) {
      if (UtilitarioGeral.valorValido(link)) {
        UtilitarioGeral.downloadArquivo(link);
      } else {
        apresentarMensagemAlerta('Arquivo indisponível');
      }
    }
    return {
      state,
      props,
      UtilitarioMascara,
      UtilitarioData,
      storeSistema,
      telaBase,
      adicionarAtalho,
      removerAtalho,
      gerarArquivo,
      EStatusArquivoFiscal,
      baixarArquivo,
      gerarNovoArquivo,
    };
  },
});
