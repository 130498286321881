import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  INotaFiscal, INotaFiscalAnexo, INotaFiscalDetalhePagamento, INotaFiscalDetalhePagamentoAnexo, INotaFiscalDetalhePagamentoCentroCusto, INotaFiscalDocumentoReferenciadoDfe, INotaFiscalEmitente, INotaFiscalImpostoTotal, INotaFiscalIncidenciaCalculo, INotaFiscalItem, INotaFiscalItemCombustivel, INotaFiscalItemEstoque, INotaFiscalItemImposto, INotaFiscalItemMedicamento, INotaFiscalMarcador, INotaFiscalTransporte, INotaFiscalTransporteVolume,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IDTONotaFiscalRegistroAtividade } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalRegistroAtividade';
import { EStatusNotaFiscal } from '@/models/Enumeradores/Compartilhados/EStatusNotaFiscal';
import { IRetornoConsultaDFe } from '@/core/models/IRetornoConsultaDFe';
import { IRetornoVisualizarDFe } from '@/core/models/IRetornoVisualizarDFe';
import { IDTONFeContraCnpj } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalContraCnpj';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import { IRetornoComunicacaoDFe } from '@/core/models/IRetornoComunicacaoDFe';
import { IRecebimentoMercadoria } from '@/models/Entidades/Compras/IRecebimentoMercadoria';
import { EStatusContraNota } from '@/models/Enumeradores/Compras/EStatusContraNota';
import { IRetornoVenda } from '@/models/Retornos/Vendas/IRetornoVenda';
import { IParametrosConsultaNotaFiscal } from '@/models/ParametrosRequisicao/Fiscal/IParametrosConsultaNotaFiscal';
import { IRetornoNotaFiscal } from '@/models/Retornos/Fiscal/IRetornoNotaFiscal';
import { EOrigemNotaFiscal } from '@/models/Enumeradores/Fiscal/EOrigemNotaFiscal';

/**
 * Serviço de Notas Fiscais
 * Fornece todas regras de negócios e lógicas relacionado a nota fiscal.
 */

class ServicoNotaFiscal {
    endPoint = 'notas-fiscais';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsultaNotaFiscal, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      let parametrosAdicionais = '';
      if (parametrosConsulta.empresas !== undefined && parametrosConsulta.empresas.length > 0) {
        parametrosConsulta.empresas.forEach((codigoEmpresa) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `Empresas=${codigoEmpresa}`;
        });
      }

      if (parametrosConsulta.periodo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

        parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
        parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
      }

      if (parametrosConsulta.valorBuscaRapida !== undefined) {
        if (UtilitarioGeral.valorValido(parametrosConsulta.valorBuscaRapida)) {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
        }
      }

      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
        parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
        parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
        parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
      if (codigosSelecionados !== undefined) {
        parametrosAdicionais += this.apiERP.obterParametroCodigosSelecionados(codigosSelecionados);
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/relatorio-padrao/${tipoArquivo}${parametrosEmpresas}${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obternotaFinanceiroConsultaRapida(codigoNotaFiscal: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoNotaFiscal.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const lista = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(lista)) {
        return lista[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(nota: INotaFiscal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, nota);
      return result.data;
    }

    public async incluirCabecalho(notaFiscal: INotaFiscal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/cabecalho`, notaFiscal);
      return result.data;
    }

    public async alterar(nota: INotaFiscal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${nota.codigo}`, nota);
      return result.data;
    }

    public async alterarInformacoesFiscais(nota: INotaFiscal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${nota.codigo}/informacoes-fiscais`, nota);
      return result.data;
    }

    public async atualizarDetalhePagamento(detalhePagamento: INotaFiscalDetalhePagamento, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/detalhe-pagamento/empresa/${empresa}`, detalhePagamento);
      return result.data;
    }

    public async atualizarAnexo(anexo: INotaFiscalAnexo, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/anexo/empresa/${empresa}`, anexo);
      return result.data;
    }

    public async atualizarVolume(volume: INotaFiscalTransporteVolume, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/volume/empresa/${empresa}`, volume);
      return result.data;
    }

    public async obter(codigo: number, obterDadosEssenciais?:boolean): Promise<INotaFiscal> {
      let parametrosAdicionais = '';
      if (obterDadosEssenciais !== undefined) {
        parametrosAdicionais += `?obterDadosEssenciais=${obterDadosEssenciais}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}${parametrosAdicionais}`);
      return result.data;
    }

    public async consultar(codigo: number): Promise<IRetornoConsultaDFe> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/consultar`);
      return result.data;
    }

    public async visualizar(codigo: number): Promise<IRetornoVisualizarDFe> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/visualizar`);
      return result.data;
    }

    public async emitir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigo}/emitir`);
      return result.data;
    }

    public async alterarStatus(codigo: number, statusNovo: number): Promise<IRetornoNotaFiscal> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${codigo}/status/${statusNovo}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async excluirVarios(empresa: number, codigosNotasFiscais: number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < codigosNotasFiscais.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigosNotasFiscais[contador]}`;
      }

      const result: any = await this.apiERP.delete(`${this.endPoint}/varios/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async excluirDetalhePagamento(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/detalhe-pagamento/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirDetalhesPagamento(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/detalhes-pagamento/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirAnexo(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/anexo/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirVolume(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/volume/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterDadosEmitente(codigo: number): Promise<INotaFiscalEmitente> {
      const result: any = await this.apiERP.get(`${this.endPoint}/dados-emitente/${codigo}`);
      return result.data;
    }

    public async obterDadosTransporte(codigo: number): Promise<INotaFiscalTransporte> {
      const result: any = await this.apiERP.get(`${this.endPoint}/dados-transporte/${codigo}`);
      return result.data;
    }

    public async obterTotaisImpostos(codigo: number): Promise<INotaFiscalImpostoTotal> {
      const result: any = await this.apiERP.get(`${this.endPoint}/totais-impostos/${codigo}`);
      return result.data;
    }

    public async obterItens(codigo: number): Promise<INotaFiscalItem[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/itens/${codigo}`);
      return result.data;
    }

    public async obterImpostoItem(codigo: number): Promise<INotaFiscalItemImposto> {
      const result: any = await this.apiERP.get(`${this.endPoint}/imposto-item/${codigo}`);
      return result.data;
    }

    public async obterDadosMedicamentoItem(codigo: number): Promise<INotaFiscalItemMedicamento> {
      const result: any = await this.apiERP.get(`${this.endPoint}/medicamento-item/${codigo}`);
      return result.data;
    }

    public async obterDadosCombustivelItem(codigo: number): Promise<INotaFiscalItemCombustivel> {
      const result: any = await this.apiERP.get(`${this.endPoint}/combustivel-item/${codigo}`);
      return result.data;
    }

    public async obterEstoquesItem(codigo: number): Promise<INotaFiscalItemEstoque[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/estoques-item/${codigo}`);
      return result.data;
    }

    public async obterDetalhesPagamento(codigo: number): Promise<INotaFiscalDetalhePagamento[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/detalhes-pagamento/${codigo}`);
      return result.data;
    }

    public async obterCentrosCustosDetalhePagamento(codigo: number): Promise<INotaFiscalDetalhePagamentoCentroCusto[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/detalhes-pagamento/centros-custos/${codigo}`);
      return result.data;
    }

    public async obterDocumentosReferenciados(codigo: number): Promise<INotaFiscalDocumentoReferenciadoDfe[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/documentos-referenciados/${codigo}`);
      return result.data;
    }

    public async obterAnexosDetalhePagamento(codigo: number): Promise<INotaFiscalDetalhePagamentoAnexo[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/detalhes-pagamento/anexos/${codigo}`);
      return result.data;
    }

    public async obterAnexos(codigo: number): Promise<INotaFiscalAnexo[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/anexos/${codigo}`);
      return result.data;
    }

    public async gerarLinkAnexo(pasta:string, nomeArquivo:string): Promise<IRetornoArquivo> {
      const result: any = await this.apiERP.get(`${this.endPoint}/anexos/gerar-link/pasta/${pasta}/nomeArquivo/${nomeArquivo}`);
      return result.data;
    }

    public async enviarAnexos(arquivos: any[], empresa: number): Promise<IRetornoArquivo[]> {
      const data = new FormData();
      for (let i = 0; i < arquivos.length; (i += 1)) {
        data.append('arquivos', arquivos[i]);
      }

      const result: any = await this.apiERP.upload(`${this.endPoint}/anexos/empresa/${empresa}`, data);
      return result.data;
    }

    public async removerAnexo(codigoNotaFiscalAnexo: number, codigoArquivo: number): Promise<IRetornoArquivo> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/anexos/${codigoNotaFiscalAnexo}/arquivo/${codigoArquivo}`);
      return result.data;
    }

    public async importarNotaContraCnpj(codigoNotaContraCnpj: number, empresa: number): Promise<IRecebimentoMercadoria> {
      const result: any = await this.apiERP.post(`${this.endPoint}/contra-cnpj/${codigoNotaContraCnpj}/empresa/${empresa}/importar`);
      return result.data;
    }

    public async manifestarNotaContraCnpj(codigoNotaContraCnpj: number, empresa: number, codigoEvento: number): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/contra-cnpj/${codigoNotaContraCnpj}/empresa/${empresa}/manifestar/${codigoEvento}`);
      return result.data;
    }

    public async importarXml(arquivo: any, empresa: number): Promise<IRecebimentoMercadoria> {
      const data = new FormData();
      data.append('arquivo', arquivo);

      const result: any = await this.apiERP.upload(`${this.endPoint}/xml/empresa/${empresa}`, data);
      return result.data;
    }

    public async consultaSefazXml(chaveAcesso: string, empresa: number): Promise<IRecebimentoMercadoria> {
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta/sefaz/${chaveAcesso}/empresa/${empresa}/xml`);
      return result.data;
    }

    public async obterNotasContraCnpj(empresa: number, valorBusca: string, periodo:EFiltroPeriodoMovimentoComercial, status: EStatusContraNota, dataInicial?: string, dataFinal?: string): Promise<IDTONFeContraCnpj[]> {
      let parametrosAdicionais = '';

      if (UtilitarioGeral.valorValido(valorBusca)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `valorBusca=${valorBusca}`;
      }

      if (UtilitarioGeral.valorValido(periodo)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${periodo}`;
      }

      if (UtilitarioGeral.valorValido(status)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `status=${status}`;
      }

      if (UtilitarioGeral.valorValido(dataInicial)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `dataInicial=${dataInicial}`;
      }

      if (UtilitarioGeral.valorValido(dataFinal)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `dataFinal=${dataFinal}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/contra-cnpj/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterMarcadores(codigo: number): Promise<INotaFiscalMarcador[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/marcadores/${codigo}`);
      return result.data;
    }

    public async consultarMarcadores(marcardor: string): Promise<string[]> {
      let parametrosAdicionais = '';
      parametrosAdicionais += `?Marcador=${marcardor}`;

      const result: any = await this.apiERP.get(`${this.endPoint}/marcadores${parametrosAdicionais}`);
      return result.data;
    }

    public async obterRegistrosAtividade(codigo: number): Promise<IDTONotaFiscalRegistroAtividade[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/registros-atividade/${codigo}`);
      return result.data;
    }

    public visualizarDocumentoFiscal(notaFiscal: INotaFiscal) : boolean {
      if (UtilitarioGeral.valorValido(notaFiscal.chaveAcesso) && (notaFiscal.status === EStatusNotaFiscal.Autorizada || notaFiscal.status === EStatusNotaFiscal.Cancelada)) {
        return true;
      }

      return false;
    }

    public nomeAmigavelModelo(modelo: string): string {
      if (UtilitarioGeral.valorValido(modelo)) {
        if (modelo === '55') {
          return 'NF-e';
        } if (modelo === '65') {
          return 'NFC-e';
        }
      }
      return '';
    }

    public verificaVisualizarDFe(modelo: string, identificador: string, status: EStatusNotaFiscal): boolean {
      if (UtilitarioGeral.valorValido(modelo) && UtilitarioGeral.valorValido(identificador) && (status === EStatusNotaFiscal.Autorizada || status === EStatusNotaFiscal.Cancelada)) {
        return true;
      }
      return false;
    }

    public async atualizarNotaContraCnpj(codigoNotaContraCnpj: number, empresa: number): Promise<boolean> {
      const result: any = await this.apiERP.post(`${this.endPoint}/contra-cnpj/${codigoNotaContraCnpj}/empresa/${empresa}/atualizar`);
      return result.data;
    }

    public async enviarEmail(codigo: number, emails: string[]): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigo}/enviar/email`, emails);
      return result.data;
    }

    public async downloadXmls(empresa: number, identificadores: string[]): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${empresa}/downloads/xmls`, identificadores);
      return result.data;
    }

    public async downloadPdfs(empresa: number, identificadores: string[]): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${empresa}/downloads/pdfs`, identificadores);
      return result.data;
    }

    public calcularTotalItem(item: INotaFiscalItem): INotaFiscalItem {
      const itemInterno = item;
      if (itemInterno.fatorConversao !== undefined) {
        itemInterno.valorTotal = parseFloat(((itemInterno.quantidade * itemInterno.precoUnitario) - itemInterno.valorDesconto).toFixed(2));
      }
      return itemInterno;
    }

    public obterIncidenciasNotaFiscalPadrao(): INotaFiscalIncidenciaCalculo[] {
      const incidenciasCalculo:INotaFiscalIncidenciaCalculo[] = [
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'IPI', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Seguro', valor: false,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Desconto', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Frete', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'OutrasDespesas', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Seguro', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'IPI', valor: true,
        },
        {
          codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Desconto', valor: true,
        },
      ];

      return incidenciasCalculo;
    }

    public async concluirEtapa(venda: number): Promise<IRetornoVenda> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${venda}/etapas/concluir`, '');
      return result.data;
    }

    public async retornarEtapa(venda: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${venda}/etapas/retornar`, '');
      return result.data;
    }

    public async imprimirVenda(venda: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${venda}/impressao/tipo-arquivo/${tipoArquivo}`);
      return result.data;
    }

    public async imprimir(codigo: number, empresa: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/impressao/${codigo}/empresa/${empresa}/tipo-arquivo/${tipoArquivo}`);
      return result.data;
    }

    public verificaSePodeExcluir(status: EStatusNotaFiscal): boolean {
      switch (status) {
        case EStatusNotaFiscal.Pendente:
          return true;
        default:

          return false;
      }
    }

    public corStatus(status: EStatusNotaFiscal): string {
      switch (status) {
        case EStatusNotaFiscal.Pendente:
          return '#969696';
        case EStatusNotaFiscal.Autorizada:
          return '#1843b8';
        case EStatusNotaFiscal.Cancelada:
          return '#cf2751';
        case EStatusNotaFiscal.EmitidaEmContigencia:
          return '#d6c720';
        default:
          return '#969696';
      }
    }

    public descricaoStatus(status: EStatusNotaFiscal): string {
      switch (status) {
        case EStatusNotaFiscal.Pendente:
          return 'Pendente';
        case EStatusNotaFiscal.Autorizada:
          return 'Autorizada';
        case EStatusNotaFiscal.Cancelada:
          return 'Cancelada';
        case EStatusNotaFiscal.EmitidaEmContigencia:
          return 'Emitida Em Contigência';
        default:
          return 'Pendente';
      }
    }

    public descricaoOrigemNotaFiscal(origem: EOrigemNotaFiscal): string {
      switch (origem) {
        case EOrigemNotaFiscal.RecebimentoMercadoria:
          return 'Recebimento Mercadoria';
        case EOrigemNotaFiscal.Importacao:
          return 'Importação';
        case EOrigemNotaFiscal.DevolucaoCompras:
          return 'Devolução Compras';
        case EOrigemNotaFiscal.Vendas:
          return 'Vendas';
        case EOrigemNotaFiscal.DevolucaoVendas:
          return 'Devolução Vendas';
        case EOrigemNotaFiscal.OutrasNotasConsumo:
          return 'Outras Notas Consumo';
        case EOrigemNotaFiscal.EmissaoNotaFiscalEletronica:
          return 'Emissão NF-e';
        default:
          return '';
      }
    }
}
export default ServicoNotaFiscal;
