
import {
  defineComponent, computed, reactive, onBeforeMount,
} from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '@/core/components/Icone.vue';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IDTONotaFiscal } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscal';
import { EEmitenteDocumentoFiscal } from '@/models/Enumeradores/Fiscal/EEmitenteDocumentoFiscal';

export default defineComponent({
  name: 'NotaFiscalListagem',
  components: {
    Icone,
    MensagemSemDados,
  },
  props: {
    visualizacaoKanban: {
      type: Boolean,
      default: true,
    },
    notasFiscais: {
      type: Array as () => IDTONotaFiscal[],
      required: true,
    },
    selecionados: {
      type: Array as () => number[],
      required: true,
    },
    paginacao: {
      type: Object as () => IPagination,
      required: true,
    },
  },
  emits: ['update:notasFiscais', 'update:visualizacaoKanban', 'update:paginacao', 'update:selecionados', 'selecaoAlterada', 'buscarNotasFiscais', 'visualizar', 'imprimir', 'novoLancamento'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { gradeBase, preencheOrdenacaoSelecionada, ordenacaoAtiva } = useGradeBase();

    const state = reactive({
      colunas: [] as IColumn[],
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
      telaMobile: false,
    });

    const computednotasFiscais = computed({
      get: () => props.notasFiscais,
      set: (valor: IDTONotaFiscal[]) => {
        emit('update:notasFiscais', valor);
      },
    });

    const computedVisualizacaoKanban = computed({
      get: () => props.visualizacaoKanban,
      set: (valor: boolean) => {
        emit('update:visualizacaoKanban', valor);
      },
    });

    const computedSelecionados = computed({
      get: () => props.selecionados,
      set: (valor: number[]) => {
        emit('update:selecionados', valor);
      },
    });

    const computedPaginacao = computed({
      get: () => props.paginacao,
      set: (valor: IPagination) => {
        emit('update:paginacao', valor);
      },
    });

    function obterClasseStatus(status: string) {
      switch (status) {
        case 'Pendente':
          return 'em-digitacao';
        case 'Em Processamento':
          return 'aguardando-emissao';
        case 'Autorizada':
          return 'concluido';
        default:
          return 'cancelado';
      }
    }

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'NF', dataIndex: 'notaFiscalServico', key: 'NotaFiscal', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true, fixed: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
          },
          {
            title: 'Nº', dataIndex: 'numero', key: 'Numero', position: 2, visible: true, ordering: true, fixed: 'left', customRenderRow: ECustomRenderRow.LinkAcao, width: 110,
          },
          {
            title: 'Dt de Emissão', dataIndex: 'dataEmissaoRps', key: 'DataEmissaoRps', position: 3, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 120, align: 'center',
          },
          {
            title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 4, visible: true, ordering: true, width: 250, align: 'center',
          },
          {
            title: 'Pessoa', dataIndex: 'nomeRazaoSocial', key: 'NomeRazaoSocial', position: 5, visible: true, ordering: true,
          },
          {
            title: 'Valor Total', dataIndex: 'totalNotaFiscal', key: 'TotalNotaFiscal', position: 6, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 100,
          },
          {
            title: 'Chave de Acesso', dataIndex: 'chaveAcesso', key: 'ChaveAcesso', position: 7, visible: true, ordering: true, width: 75, align: 'center',
          },
          {
            title: 'Ações', key: 'acoes', position: 8, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
          },
        ];
      }
    }

    async function ordenarDados(key: string, sort: string) {
      await preencheOrdenacaoSelecionada(key, sort);
      emit('buscarNotasFiscais', gradeBase.ordenacaoSelecionada);
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        computedPaginacao.value.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        emit('buscarNotasFiscais');
      }, 600);
    }

    async function mudarPagina(current: number, pageSize: number) {
      computedPaginacao.value.current = current;
      computedPaginacao.value.pageSize = pageSize;
      emit('buscarNotasFiscais');
    }

    async function mostrarNovaPagina(current: number, pageSize: number) {
      computedPaginacao.value.current = 1;
      computedPaginacao.value.pageSize = pageSize;
      emit('buscarNotasFiscais');
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      computedSelecionados.value = selectedRowKeys;
      emit('selecaoAlterada');
    };

    function visualizar(index: number) {
      emit('visualizar', props.notasFiscais[index].codigo);
    }

    function imprimir(index: number) {
      emit('imprimir', props.notasFiscais[index].codigo);
    }

    function novoLancamento() {
      emit('novoLancamento');
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
      computedPaginacao.value.total = 100;
      computedPaginacao.value.showSizeChanger = true;
      computedPaginacao.value.pageSize = 100;
      computedPaginacao.value.pageSizeOptions = ['10', '50', '100', '500', '1000'];
    });

    function obterNomeRazaoSocialPessoa(notaFiscal: IDTONotaFiscal) {
      if (notaFiscal.tipoEmissao === EEmitenteDocumentoFiscal.EmissaoPropria) {
        return notaFiscal.nomeRazaoSocialDestinatario;
      }
      return notaFiscal.nomeRazaoSocialEmitente;
    }
    return {
      state,
      props,
      window,
      telaBase,
      gradeBase,
      computednotasFiscais,
      computedVisualizacaoKanban,
      computedSelecionados,
      computedPaginacao,
      UtilitarioMascara,
      UtilitarioData,
      mudarPagina,
      ordenarDados,
      mostrarNovaPagina,
      alterarQuantidadeRegistroTotal,
      obterClasseStatus,
      mudarSelecao,
      ordenacaoAtiva,
      ECustomRenderRow,
      visualizar,
      imprimir,
      novoLancamento,
      obterNomeRazaoSocialPessoa,
    };
  },
});

