
import {
  defineComponent, inject, reactive, watch, Ref, provide,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useRequisicaoAutorizacaoBase } from '@/composables/MeuSistema/RequisicaoAutorizacaoBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarOrigemNotaFiscal from '@/components/Fiscal/SelecionarOrigemNotaFiscal.vue';
import SelecionarTipoOperacao from '@/components/Fiscal/SelecionarTipoOperacao.vue';
import SelecionarTipoEmissao from '@/components/Fiscal/SelecionarTipoEmissao.vue';
import SelecionarFinalidade from '@/components/Fiscal/SelecionarFinalidade.vue';
import SelecionarTipoEmissaoNFe from '@/components/Fiscal/SelecionarTipoEmissaoNFe.vue';
import SelecionarTipoEmissaoNFCe from '@/components/Fiscal/SelecionarTipoEmissaoNFCe.vue';
import SelecionarIndicadorPresenca from '@/components/Fiscal/SelecionarIndicadorPresenca.vue';
import SelecionarModeloDocumentoFiscal from '@/components/Compartilhados/SelecionarModeloDocumentoFiscal.vue';
import SelecionarSituacaoDocumentoFiscal from '@/components/Fiscal/SelecionarSituacaoDocumentoFiscal.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { ETipoFrete } from '@/models/Enumeradores/Compartilhados/ETipoFrete';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarCenarioFiscal from '@/components/Cadastros/Tributacoes/CenariosFiscais/SelecionarCenarioFiscal.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import SelecionarMarcadorNotaFiscal from '@/components/Fiscal/NotasFiscais/SelecionarMarcadorNotaFiscal.vue';
import NotaFiscalItens from '@/components/Fiscal/NotasFiscais/NotaFiscalItens.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import {
  INotaFiscal, INotaFiscalAnexo, INotaFiscalDestinatario, INotaFiscalDetalhePagamento, INotaFiscalEmitente, INotaFiscalImpostoTotal, INotaFiscalIncidenciaCalculo, INotaFiscalItem, INotaFiscalMarcador, INotaFiscalTransporte, INotaFiscalTransporteVolume,
} from '@/models/Entidades/Fiscal/INotaFiscal';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import { ETipoValorMovimentacao } from '@/models/Enumeradores/ETipoValorMovimentacao';
import ServicoCalculosComerciais from '@/servicos/ServicoCalculosComerciais';
import { EOrigemValorItem } from '@/models/Enumeradores/EOrigemValorItem';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import RequisitarAutorizacaoModal from '@/components/MeuSistema/ControleAutorizacoes/RequisitarAutorizacaoModal.vue';
import NotaFiscalImpostos from '@/components/Fiscal/NotasFiscais/NotaFiscalImpostos.vue';
import NotaFiscalFrete from '@/components/Fiscal/NotasFiscais/NotaFiscalFrete.vue';
import NotaFiscalAnexos from '@/components/Fiscal/NotasFiscais/NotaFiscalAnexos.vue';
import NotaFiscalHistorico from '@/components/Fiscal/NotasFiscais/NotaFiscalHistorico.vue';
import NotaFiscalDocumentosReferenciados from '@/components/Fiscal/NotasFiscais/NotaFiscalDocumentosReferenciados.vue';
import { ETipoOperacao } from '@/models/Enumeradores/Fiscal/ETipoOperacao';
import { ETipoEmissao } from '@/models/Enumeradores/Fiscal/ETipoEmissao';
import { IDTONotaFiscalRegistroAtividade } from '@/models/DTO/Fiscal/NotasFiscais/IDTONotaFiscalRegistroAtividade';
import { useFocusBase } from '@/core/composables/FocusBase';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';
import NotaFiscalDetalhesPagamento from '@/components/Fiscal/NotasFiscais/NotaFiscalDetalhesPagamento.vue';
import NotaFiscalDescontosAcrescimos from '@/components/Fiscal/NotasFiscais/NotaFiscalDescontosAcrescimos.vue';
import ServicoCfop from '@/servicos/Cadastros/Tributacoes/ServicoCfop';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import BarraProgresso from '@/core/components/Tela/BarraProgresso.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';
import { INotaFiscalServicoItem } from '@/models/Entidades/Fiscal/INotaFiscalServico';
import ServicoEmpresa from '@/servicos/MeuSistema/ServicoEmpresa';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EOrigemNotaFiscal } from '@/models/Enumeradores/Fiscal/EOrigemNotaFiscal';
import { EStatusNotaFiscal } from '@/models/Enumeradores/Compartilhados/EStatusNotaFiscal';
import { ESituacaoDocumentoFiscal } from '@/models/Enumeradores/Fiscal/ESituacaoDocumentoFiscal';
import { EFinalidadeEmissaoNFe } from '@/models/Enumeradores/Fiscal/EFinalidadeEmissaoNFe';
import { EIndicadorPresenca } from '@/models/Enumeradores/Fiscal/EIndicadorPresenca';
import { EModalidadeEmissaoNFe } from '@/models/Enumeradores/Fiscal/EModalidadeEmissaoNFe';

export default defineComponent({
  name: 'NotaFiscalModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    PreviewPdf,
    Icone,
    BarraProgresso,
    EditorHtml,
    SelecionarData,
    SelecionarOrigemNotaFiscal,
    SelecionarTipoOperacao,
    SelecionarTipoEmissao,
    SelecionarFinalidade,
    SelecionarTipoEmissaoNFe,
    SelecionarTipoEmissaoNFCe,
    SelecionarIndicadorPresenca,
    SelecionarModeloDocumentoFiscal,
    SelecionarSituacaoDocumentoFiscal,
    SelecionarPessoa,
    SelecionarCenarioFiscal,
    SelecionarMarcadorNotaFiscal,
    NotaFiscalItens,
    RequisitarAutorizacaoModal,
    NotaFiscalImpostos,
    NotaFiscalFrete,
    NotaFiscalDescontosAcrescimos,
    NotaFiscalDetalhesPagamento,
    NotaFiscalDocumentosReferenciados,
    NotaFiscalAnexos,
    NotaFiscalHistorico,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const servicoCalculosComerciais = new ServicoCalculosComerciais();
    const servicoEmpresas = new ServicoEmpresa();
    const servicoUtilitario = new ServicoUtilitario();
    servicoEmpresas.requisicaoSistema();
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    const servicoSistema = new ServicoSistema();
    const servicoPessoa = new ServicoPessoa();
    servicoPessoa.requisicaoSistema();

    const servicoCfop = new ServicoCfop();
    servicoCfop.requisicaoSistema();

    const {
      telaBase,
      apresentarMensagemSucesso, apresentarMensagemAlerta, preencherEmpresasDisponiveis, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      focusBase, focusElemento, proximoElemento, elementoAnterior,
    } = useFocusBase();

    focusBase.classElementos = 'ss-nota-fiscal-eletronica-dados-principais';

    const { requisicaoAutorizacaoBase } = useRequisicaoAutorizacaoBase();
    const dadosEmpresa = inject('NOTAS_FISCAIS_EMPRESA') as Ref<IDTODadosEmpresa>;

    telaBase.identificadorRecurso = 'NOTAS_FISCAIS';
    let debounceCacheRegistro = 0;
    let debounceAtualizacaoTotalizadores = 0;
    let debounceRenderizar = 0;
    const state = reactive({
      notaFiscal: {} as INotaFiscal,
      dadosPessoaMovimento: {} as IDTOPessoaMovimento,
      codigoCenarioFiscal: 0,
      tipoEmissaoAnterior: ETipoEmissao.EmissaoPropria,
      numeroNotaFiscalAnterior: 0,
      atualizarValores: false,
      atualizarApenasInformacoesFiscais: false,
      ufEmpresa: '',
      marcadores: [] as string[],
      registrosAtividade: [] as IDTONotaFiscalRegistroAtividade[],
      incidenciasCalculo: [] as INotaFiscalIncidenciaCalculo[],
      servicos: [] as INotaFiscalServicoItem[],
      cacheRegistro: {} as ISalvamentoAutomatico,
      tabelasPrecosDisponiveis: [] as number[],
      permitirNfe: true,
      permitirNfce: true,
      regimeTributario: 0,
      regimeTributarioEspecial: 0,
      totalCaixaLancado: 0,
      totalCarteiraLancado: 0,
      totalOutrosLancado: 0,
      telaMobile: false,
      editavel: false,
      cacheDisponivel: false,
      calculandoValores: false,
      chaveItens: 0,
      exibirAlteracaoEtapa: false,
      carregando: false,
      carregandoItens: false,
      adicionarPrimeiroItem: false,
      buscarDadosFiscaisRecalcular: false,
      criacaoVendaAutomatico: false,
      cfopSugerido: {} as ICfop,
      incluindoCabecalhoNotaFiscal: false,
      arquivosPdf: [] as IArquivoPdf[],
      valorTotalImpostos: 0,
      totalDetalhesPagamento: 0,
    });

    provide('NOTAS_FISCAIS_INCIDENCIA_CALCULO', state.incidenciasCalculo);
    provide('NOTAS_FISCAIS_CFOP_SUGERIDO', state.cfopSugerido);

    async function limparTela() {
      state.editavel = true;
      state.atualizarApenasInformacoesFiscais = false;
      state.codigoCenarioFiscal = 0;
      state.criacaoVendaAutomatico = false;
      state.incluindoCabecalhoNotaFiscal = false;
      state.adicionarPrimeiroItem = false;
      state.buscarDadosFiscaisRecalcular = false;
      state.incidenciasCalculo = servicoNotaFiscal.obterIncidenciasNotaFiscalPadrao();
      state.totalCaixaLancado = 0;
      state.totalCarteiraLancado = 0;
      state.totalOutrosLancado = 0;
      state.notaFiscal = {} as INotaFiscal;
      state.notaFiscal.codigo = 0;
      state.notaFiscal.origemNotaFiscal = EOrigemNotaFiscal.EmissaoNotaFiscalEletronica;
      state.notaFiscal.numeroNotaFiscal = 0;
      state.notaFiscal.naturezaOperacao = 'NATUREZA';
      state.notaFiscal.codigoEmpresa = dadosEmpresa.value.codigoEmpresa;
      state.notaFiscal.destinatario = {} as INotaFiscalDestinatario;
      state.notaFiscal.destinatario.codigoPessoa = 0;
      state.notaFiscal.pessoaIdentificada = true;
      state.notaFiscal.itens = [] as INotaFiscalItem[];
      state.notaFiscal.status = EStatusNotaFiscal.Pendente;
      state.notaFiscal.situacao = ESituacaoDocumentoFiscal.DocumentoRegular;
      state.notaFiscal.tipoOperacao = ETipoOperacao.Saida;
      state.notaFiscal.tipoFrete = ETipoFrete.SemOcorrenciaTransporte;
      state.notaFiscal.tipoEmissao = ETipoEmissao.EmissaoPropria;
      state.notaFiscal.finalidade = EFinalidadeEmissaoNFe.NFeNormal;
      state.notaFiscal.indicadorPresenca = EIndicadorPresenca.OperacaoPresencial;
      state.notaFiscal.modalidadeEmissao = EModalidadeEmissaoNFe.EmissaoNormal;
      state.notaFiscal.modelo = '55';
      state.notaFiscal.serie = 1;
      state.notaFiscal.dataEmissao = UtilitarioData.obterDataAtual();
      state.notaFiscal.dataEntradaSaida = UtilitarioData.obterDataAtual();
      state.notaFiscal.dataRegistro = UtilitarioData.obterDataAtual();
      state.notaFiscal.emitente = {} as INotaFiscalEmitente;
      state.notaFiscal.emitente.codigoPessoa = 0;
      state.notaFiscal.totalImpostos = {} as INotaFiscalImpostoTotal;
      state.notaFiscal.transporte = {} as INotaFiscalTransporte;
      state.notaFiscal.transporte.codigoTransportadora = 0;
      state.notaFiscal.transporte.volumes = [] as INotaFiscalTransporteVolume[];
      state.notaFiscal.totalDesconto = 0;
      state.notaFiscal.totalFrete = 0;
      state.notaFiscal.totalNotaFiscal = 0;
      state.notaFiscal.totalOutrasDespesas = 0;
      state.notaFiscal.totalProdutos = 0;
      state.notaFiscal.totalSeguro = 0;
      state.notaFiscal.totalImpostos.valorAproximadoTributosTotal = 0;
      state.notaFiscal.anexos = [] as INotaFiscalAnexo[];
      state.notaFiscal.detalhesPagamento = [] as INotaFiscalDetalhePagamento[];
      state.servicos = [] as INotaFiscalServicoItem[];
      state.marcadores = [] as string[];
      state.registrosAtividade = [] as IDTONotaFiscalRegistroAtividade[];

      state.tipoEmissaoAnterior = ETipoEmissao.EmissaoPropria;
      state.numeroNotaFiscalAnterior = 0;
      state.cacheDisponivel = false;
      state.cacheRegistro = {} as ISalvamentoAutomatico;
      state.cacheRegistro.codigo = 0;
      state.exibirAlteracaoEtapa = false;
      state.carregando = false;
      state.carregandoItens = false;
    }

    function obterIdentificadorCache() {
      return `${telaBase.identificadorRecurso}_${state.notaFiscal.codigo}`;
    }

    async function salvarCacheRegistro() {
      clearTimeout(debounceCacheRegistro);
      debounceCacheRegistro = setTimeout(async () => {
        state.cacheRegistro.data = UtilitarioData.obterDataAtual();
        state.cacheRegistro.codigoEmpresa = state.notaFiscal.codigoEmpresa;
        state.cacheRegistro.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
        state.cacheRegistro.identificador = obterIdentificadorCache();
        state.cacheRegistro.dados = JSON.stringify(state.notaFiscal);
        await servicoSistema.salvamentoAutomaticoLocalStorage(`${obterIdentificadorCache()}_${state.cacheRegistro.codigoUsuario}`, state.cacheRegistro);
        const retornoSalvamento = await servicoSistema.salvamentoAutomaticoApi(state.cacheRegistro);
        if (retornoSalvamento.status === EStatusRetornoRequisicao.Sucesso) {
          state.cacheRegistro.codigo = retornoSalvamento.codigoRegistro;
        }
      }, 600);
    }

    async function verificarPermissaoNFeNFce() {
      if (state.dadosPessoaMovimento.uf !== dadosEmpresa.value.uf) {
        state.permitirNfe = true;
        state.permitirNfce = false;
        state.notaFiscal.modelo = '55';
        return;
      }

      if (state.dadosPessoaMovimento.tipoPessoa === ETipoPessoa.Fisica) {
        const cpfsInvalidos = ['00000000000', '11111111111', '22222222222', '33333333333', '44444444444', '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'];
        if (cpfsInvalidos.includes(state.dadosPessoaMovimento.cpfCnpj)) {
          state.permitirNfe = false;
          state.permitirNfce = true;
          state.notaFiscal.modelo = '65';
          return;
        }

        const cpfValido = await servicoUtilitario.validarCpfCnpj(state.dadosPessoaMovimento.cpfCnpj);
        if (!cpfValido.sucesso) {
          state.permitirNfe = false;
          state.permitirNfce = true;
          state.notaFiscal.modelo = '65';
          return;
        }
      }

      state.permitirNfe = true;
      state.permitirNfce = true;
      state.notaFiscal.modelo = '55';
    }

    async function atualizarValoresTotais() {
      if (!telaBase.carregando && !state.carregandoItens) {
        clearTimeout(debounceAtualizacaoTotalizadores);
        debounceAtualizacaoTotalizadores = setTimeout(async () => {
          state.notaFiscal.totalProdutos = 0;
          state.notaFiscal.totalDesconto = 0;
          state.notaFiscal.totalImpostos.baseCalculoIcmsTotal = 0;
          state.notaFiscal.totalImpostos.valorIcmsTotal = 0;
          state.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal = 0;
          state.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal = 0;
          state.notaFiscal.totalImpostos.baseCalculoIpiTotal = 0;
          state.notaFiscal.totalImpostos.valorIpiTotal = 0;
          state.notaFiscal.totalImpostos.baseCalculoPisTotal = 0;
          state.notaFiscal.totalImpostos.valorPisTotal = 0;
          state.notaFiscal.totalImpostos.baseCalculoCofinsTotal = 0;
          state.notaFiscal.totalImpostos.valorCofinsTotal = 0;
          state.notaFiscal.totalImpostos.valorAproximadoTributosTotal = 0;
          state.valorTotalImpostos = 0;

          for (let index = 0; index < state.notaFiscal.itens.length; index += 1) {
            if (UtilitarioGeral.validaCodigo(state.notaFiscal.itens[index].codigoProdutoDefinicao)) {
              state.notaFiscal.itens[index].valorTotal = servicoCalculosComerciais.calculaValorTotal(state.notaFiscal.itens[index].quantidade, state.notaFiscal.itens[index].precoUnitario, state.notaFiscal.itens[index].valorDesconto, 0);
              if (state.notaFiscal.itens[index].compoeTotal) {
                state.notaFiscal.totalProdutos += state.notaFiscal.itens[index].quantidade * state.notaFiscal.itens[index].precoUnitario;
                state.notaFiscal.totalDesconto += state.notaFiscal.itens[index].valorDesconto;
              }
            }
          }

          state.notaFiscal.totalNotaFiscal = state.notaFiscal.totalProdutos;
          for (let index = 0; index < state.notaFiscal.itens.length; index += 1) {
            if (UtilitarioGeral.validaCodigo(state.notaFiscal.itens[index].codigoProdutoDefinicao)) {
              const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.notaFiscal.itens[index].quantidade * state.notaFiscal.itens[index].precoUnitario));

              if (parseFloat((subTotalProduto).toFixed(2)) < parseFloat(((state.notaFiscal.itens[index].valorDesconto)).toFixed(2))) {
                state.notaFiscal.itens[index].valorDesconto = 0;
              }

              if (state.notaFiscal.itens[index].origemFrete === EOrigemValorItem.Rateio) {
                if (state.notaFiscal.totalFrete > 0) {
                  state.notaFiscal.itens[index].valorFrete = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.notaFiscal.totalProdutos, state.notaFiscal.totalFrete);
                } else {
                  state.notaFiscal.itens[index].valorFrete = 0;
                }
              }

              if (state.notaFiscal.itens[index].origemOutrasDespesas === EOrigemValorItem.Rateio) {
                if (state.notaFiscal.totalOutrasDespesas > 0) {
                  state.notaFiscal.itens[index].outrasDespesas = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.notaFiscal.totalProdutos, state.notaFiscal.totalOutrasDespesas);
                } else {
                  state.notaFiscal.itens[index].outrasDespesas = 0;
                }
              }

              if (state.notaFiscal.itens[index].origemSeguro === EOrigemValorItem.Rateio) {
                if (state.notaFiscal.totalSeguro > 0) {
                  state.notaFiscal.itens[index].valorSeguro = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.notaFiscal.totalProdutos, state.notaFiscal.totalSeguro);
                } else {
                  state.notaFiscal.itens[index].valorSeguro = 0;
                }
              }
              state.notaFiscal.itens[index].impostos.icms.aliquotaSimplesNacional = dadosEmpresa.value.aliquotaSimplesNacional;
              const retornoCalculoTributacoes = await servicoCalculosComerciais.calcularTributacoes(state.notaFiscal.itens[index], state.incidenciasCalculo, dadosEmpresa.value.uf);
              if (UtilitarioGeral.objetoValido(retornoCalculoTributacoes)) {
                state.notaFiscal.itens[index].impostos.icms.baseCalculoIcms = retornoCalculoTributacoes.icms.baseCalculoIcms;
                state.notaFiscal.itens[index].impostos.icms.valorIcms = retornoCalculoTributacoes.icms.valorIcms;
                state.notaFiscal.itens[index].impostos.icms.valorSimplesNacional = retornoCalculoTributacoes.icms.valorSimplesNacional;

                state.notaFiscal.totalImpostos.baseCalculoIcmsTotal += state.notaFiscal.itens[index].impostos.icms.baseCalculoIcms;
                state.notaFiscal.totalImpostos.valorIcmsTotal += state.notaFiscal.itens[index].impostos.icms.valorIcms;

                state.notaFiscal.itens[index].impostos.st.baseCalculoSubstituicaoTributaria = retornoCalculoTributacoes.icms.baseCalculoSubstituicaoTributaria;
                state.notaFiscal.itens[index].impostos.st.valorSubstituicaoTributaria = retornoCalculoTributacoes.icms.valorSubstituicaoTributaria;

                state.notaFiscal.totalImpostos.baseCalculoIcmsSubstituicaoTotal += state.notaFiscal.itens[index].impostos.st.baseCalculoSubstituicaoTributaria;
                state.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal += state.notaFiscal.itens[index].impostos.st.valorSubstituicaoTributaria;

                state.notaFiscal.itens[index].impostos.ipi.baseCalculoIpi = retornoCalculoTributacoes.ipi.baseCalculo;
                state.notaFiscal.itens[index].impostos.ipi.valorIpi = retornoCalculoTributacoes.ipi.valor;

                state.notaFiscal.totalImpostos.baseCalculoIpiTotal += state.notaFiscal.itens[index].impostos.ipi.baseCalculoIpi;
                state.notaFiscal.totalImpostos.valorIpiTotal += state.notaFiscal.itens[index].impostos.ipi.valorIpi;

                state.notaFiscal.itens[index].impostos.pis.baseCalculoPis = retornoCalculoTributacoes.pis.baseCalculo;
                state.notaFiscal.itens[index].impostos.pis.valorPis = retornoCalculoTributacoes.pis.valor;

                state.notaFiscal.totalImpostos.baseCalculoPisTotal += state.notaFiscal.itens[index].impostos.pis.baseCalculoPis;
                state.notaFiscal.totalImpostos.valorPisTotal += state.notaFiscal.itens[index].impostos.pis.valorPis;

                state.notaFiscal.itens[index].impostos.cofins.baseCalculoCofins = retornoCalculoTributacoes.cofins.baseCalculo;
                state.notaFiscal.itens[index].impostos.cofins.valorCofins = retornoCalculoTributacoes.cofins.valor;

                state.notaFiscal.totalImpostos.baseCalculoCofinsTotal += state.notaFiscal.itens[index].impostos.cofins.baseCalculoCofins;
                state.notaFiscal.totalImpostos.valorCofinsTotal += state.notaFiscal.itens[index].impostos.cofins.valorCofins;

                state.notaFiscal.itens[index].impostos.valorTotalTributos = retornoCalculoTributacoes.valorTotalTributos;
                state.notaFiscal.totalImpostos.valorAproximadoTributosTotal += state.notaFiscal.itens[index].impostos.valorTotalTributos;

                state.valorTotalImpostos = state.notaFiscal.totalImpostos.valorIcmsTotal + state.notaFiscal.totalImpostos.valorIcmsSubstituicaoTotal + state.notaFiscal.totalImpostos.valorPisTotal + state.notaFiscal.totalImpostos.valorCofinsTotal;
              }
            }
          }

          state.notaFiscal.totalNotaFiscal = state.notaFiscal.totalProdutos;
          const incidenciaTotal = state.incidenciasCalculo.filter((c) => c.incidencia === 'TotalNota');
          incidenciaTotal.forEach((itemIncidencia) => {
            if (itemIncidencia.identificador === 'IPI' && itemIncidencia.valor) {
              state.notaFiscal.totalNotaFiscal += state.notaFiscal.totalImpostos.valorIpiTotal;
            }
            if (itemIncidencia.identificador === 'Frete' && itemIncidencia.valor) {
              state.notaFiscal.totalNotaFiscal += state.notaFiscal.totalFrete;
            }
            if (itemIncidencia.identificador === 'Seguro' && itemIncidencia.valor) {
              state.notaFiscal.totalNotaFiscal += state.notaFiscal.totalSeguro;
            }
            if (itemIncidencia.identificador === 'OutrasDespesas' && itemIncidencia.valor) {
              state.notaFiscal.totalNotaFiscal += state.notaFiscal.totalOutrasDespesas;
            }
            if (itemIncidencia.identificador === 'Desconto' && itemIncidencia.valor) {
              state.notaFiscal.totalNotaFiscal -= state.notaFiscal.totalDesconto;
            }
          });

          state.notaFiscal.percentualDesconto = UtilitarioMatematico.calcularPorcentagem(state.notaFiscal.totalProdutos, state.notaFiscal.totalDesconto);

          salvarCacheRegistro();
        }, 600);
      }
    }

    async function obterRegimeTributario() {
      const dadosEmpresa = await servicoEmpresas.obter(state.notaFiscal.codigoEmpresa);
      state.regimeTributario = dadosEmpresa.regimeTributario;
      state.regimeTributarioEspecial = dadosEmpresa.regimeTributarioEspecial;
    }

    async function atualizarDescontoGeral() {
      for (let index = 0; index < state.notaFiscal.itens.length; index += 1) {
        if (UtilitarioGeral.validaCodigo(state.notaFiscal.itens[index].codigoProdutoDefinicao)) {
          const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.notaFiscal.itens[index].quantidade * state.notaFiscal.itens[index].precoUnitario));

          if (state.notaFiscal.totalDesconto > 0) {
            state.notaFiscal.itens[index].valorDesconto = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.notaFiscal.totalProdutos, state.notaFiscal.totalDesconto);
          } else {
            state.notaFiscal.itens[index].valorDesconto = 0;
          }
        }
      }
      atualizarValoresTotais();
    }

    function adicionarTaxasPagamento(acrescimo: number, outrasTaxas: number) {
      state.notaFiscal.totalOutrasDespesas += outrasTaxas;
      if (acrescimo > 0) {
        state.notaFiscal.itens.forEach((i, index) => {
          const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.notaFiscal.itens[index].quantidade * state.notaFiscal.itens[index].precoUnitario));
          const acrescimoItem = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.notaFiscal.totalProdutos, acrescimo);
          state.notaFiscal.itens[index].precoUnitario += (acrescimoItem / state.notaFiscal.itens[index].quantidade);
        });
        atualizarValoresTotais();
      }
    }

    function removerTaxasPagamento(acrescimo: number, outrasTaxas: number) {
      state.notaFiscal.totalOutrasDespesas -= outrasTaxas;
      state.notaFiscal.itens.forEach((i, index) => {
        const subTotalProduto = servicoCalculosComerciais.formatarValor2CasasDecimais((state.notaFiscal.itens[index].quantidade * state.notaFiscal.itens[index].precoUnitario));
        if (acrescimo > 0) {
          const acrescimoItem = servicoCalculosComerciais.calculaValorRateio(subTotalProduto, state.notaFiscal.totalProdutos, acrescimo);
          state.notaFiscal.itens[index].precoUnitario -= (acrescimoItem / state.notaFiscal.itens[index].quantidade);
        }
      });
      atualizarValoresTotais();
    }

    async function atualizarInformacoesPessoa() {
      const codigoPessoa = state.notaFiscal.tipoEmissao === ETipoEmissao.EmissaoPropria ? state.notaFiscal.destinatario.codigoPessoa : state.notaFiscal.emitente.codigoPessoa;
      state.dadosPessoaMovimento = await servicoPessoa.obterDadosMovimentacao(codigoPessoa, state.notaFiscal.codigoEmpresa);
      let cfop = 0;

      if (state.dadosPessoaMovimento.uf === dadosEmpresa.value.uf) {
        cfop = 5102;
      } else if (state.dadosPessoaMovimento.uf === 'EX') {
        cfop = 7102;
      } else {
        cfop = 6102;
      }

      state.cfopSugerido = await servicoCfop.obterPeloCodigoCfop(cfop);

      state.buscarDadosFiscaisRecalcular = true;
      verificarPermissaoNFeNFce();
    }

    async function atualizarInformacoesColaborador() {
      await salvarCacheRegistro();
    }

    async function recuperarCacheRegistro() {
      if (UtilitarioGeral.valorValido(state.cacheRegistro.dados)) {
        telaBase.carregando = true;
        const vendaCache = JSON.parse(state.cacheRegistro.dados);
        if (UtilitarioGeral.objetoValido(vendaCache)) {
          state.notaFiscal = vendaCache;
          state.cacheDisponivel = false;
          state.cacheRegistro = {} as ISalvamentoAutomatico;
          state.cacheRegistro.codigo = 0;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
        telaBase.carregando = false;
      }
    }

    async function excluirCacheRegistro() {
      servicoSistema.removerSalvamentoAutomaticoApi(dadosEmpresa.value.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), obterIdentificadorCache());
      servicoSistema.removerSalvamentoAutomaticoLocalStorage(`${obterIdentificadorCache()}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
    }

    async function obterCacheRegistro() {
      const dataAlteracao = UtilitarioData.obterDataAtual();

      const cacheApi = await servicoSistema.obterUltimoSalvamentoAutomaticoApi(dadosEmpresa.value.codigoEmpresa, storeSistema.getters.codigoUsuarioAutenticado(), obterIdentificadorCache());
      const cacheLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(`${obterIdentificadorCache()}_${storeSistema.getters.codigoUsuarioAutenticado()}`);
      state.cacheRegistro = servicoSistema.obterSalvamentoMaisRecente(cacheApi, cacheLocalStorage);

      if (UtilitarioData.validaDataPreenchida(state.cacheRegistro.data) && (props.operacao.codigoRegistro === 0 || props.operacao.codigoRegistro === undefined)) {
        state.cacheDisponivel = true;
        excluirCacheRegistro();
      } else if (UtilitarioData.validaDataPreenchida(state.cacheRegistro.data) && UtilitarioData.verificaDataMaisRecente(state.cacheRegistro.data, dataAlteracao)) {
        const vendaCache = JSON.parse(state.cacheRegistro.dados);
        if (vendaCache !== state.notaFiscal) {
          state.cacheDisponivel = true;
          excluirCacheRegistro();
        }
      }
    }

    async function obterMarcadores() {
      state.notaFiscal.marcadores.forEach((m) => {
        state.marcadores.push(m.marcador);
      });
    }

    function preencherMarcadores() {
      const marcadores = [] as INotaFiscalMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as INotaFiscalMarcador;
        marcador.codigoNotaFiscal = state.notaFiscal.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    async function buscarItens() {
      const itens = await servicoNotaFiscal.obterItens(state.notaFiscal.codigo);
      if (UtilitarioGeral.validaLista(itens)) {
        for (let index = 0; index < itens.length; index += 1) {
          if (UtilitarioGeral.validaCodigo(itens[index].impostos.codigoCenarioFiscal)) {
            state.codigoCenarioFiscal = itens[index].impostos.codigoCenarioFiscal;
            break;
          }
        }
      }
      state.notaFiscal.itens = itens;
    }

    async function buscarItensDadosAtualizados() {
      await buscarItens();
    }

    async function obterItens() {
      state.carregandoItens = true;
      await buscarItens();
      state.carregandoItens = false;
    }

    async function obterNotaFiscal(codigo: number, buscarItens = true) {
      state.criacaoVendaAutomatico = false;
      telaBase.carregando = true;

      state.notaFiscal = await servicoNotaFiscal.obter(codigo);
      state.editavel = false;
      state.atualizarApenasInformacoesFiscais = false;
      if (state.notaFiscal.status === EStatusNotaFiscal.Pendente) {
        if (state.notaFiscal.origemNotaFiscal === EOrigemNotaFiscal.EmissaoNotaFiscalEletronica || state.notaFiscal.origemNotaFiscal === EOrigemNotaFiscal.OutrasNotasConsumo) {
          state.editavel = true;
        } else {
          state.atualizarApenasInformacoesFiscais = true;
        }
      } else {
        state.atualizarApenasInformacoesFiscais = true;
      }

      state.tipoEmissaoAnterior = state.notaFiscal.tipoEmissao;
      state.numeroNotaFiscalAnterior = state.notaFiscal.numeroNotaFiscal;

      state.notaFiscal.percentualDesconto = UtilitarioMatematico.calcularPorcentagem(state.notaFiscal.totalProdutos, state.notaFiscal.totalDesconto);
      state.totalCaixaLancado = 0;
      state.totalCarteiraLancado = 0;
      state.totalOutrosLancado = 0;
      state.notaFiscal.detalhesPagamento.forEach((d) => {
        if (d.gerarCaixa) {
          state.totalCaixaLancado += d.valorTotal;
        } else if (d.gerarCarteira) {
          state.totalCarteiraLancado += d.valorTotal;
        } else {
          state.totalOutrosLancado += d.valorTotal;
        }
      });
      await atualizarInformacoesPessoa();
      state.notaFiscal.itens = [] as INotaFiscalItem[];
      if (UtilitarioGeral.validaLista(state.notaFiscal.incidenciasCalculo)) {
        state.incidenciasCalculo = state.notaFiscal.incidenciasCalculo;
      }
      telaBase.carregando = false;
      obterMarcadores();
      if (buscarItens) {
        await obterItens();
      }

      state.registrosAtividade = await servicoNotaFiscal.obterRegistrosAtividade(state.notaFiscal.codigo);

      if (state.editavel && !UtilitarioGeral.validaLista(state.notaFiscal.itens)) {
        state.adicionarPrimeiroItem = true;
      }
    }

    function validarCampos() {
      if (state.notaFiscal.tipoEmissao === ETipoEmissao.EmissaoPropria) {
        if (!UtilitarioGeral.validaCodigo(state.notaFiscal.destinatario.codigoPessoa)) {
          apresentarMensagemAlerta('A pessoa deve ser informada!');
          return false;
        }
      } else if (state.notaFiscal.tipoEmissao === ETipoEmissao.EmissaoTerceiro) {
        if (!UtilitarioGeral.validaCodigo(state.notaFiscal.emitente.codigoPessoa)) {
          apresentarMensagemAlerta('A pessoa deve ser informada!');
          return false;
        }
      }

      return true;
    }

    async function salvar() {
      if (!validarCampos()) return;

      state.criacaoVendaAutomatico = false;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      for (let index = 0; index < state.notaFiscal.detalhesPagamento.length; index += 1) {
        if (!UtilitarioGeral.validaCodigo(state.notaFiscal.detalhesPagamento[index].codigoPlanoContaCategoria)) {
          retorno.status = EStatusRetornoRequisicao.Erro;
          apresentarMensagemAlerta('Todos os detalhes de pagamento devem ter categoria definida!');
          break;
        }
        if (state.notaFiscal.origemNotaFiscal === EOrigemNotaFiscal.EmissaoNotaFiscalEletronica || state.notaFiscal.origemNotaFiscal === EOrigemNotaFiscal.OutrasNotasConsumo) {
          state.notaFiscal.detalhesPagamento[index].gerarCarteira = true;
        }
      }

      state.notaFiscal.marcadores = preencherMarcadores();
      state.notaFiscal.incidenciasCalculo = state.incidenciasCalculo;
      state.notaFiscal.detalhesPagamento.forEach((d, indexD) => {
        state.notaFiscal.detalhesPagamento[indexD].codigoNotaFiscal = state.notaFiscal.codigo;
      });

      if (UtilitarioGeral.validaLista(state.notaFiscal.itens)) {
        const indexSemCodigos: number[] = [];
        for (let index = 0; index < state.notaFiscal.itens.length; index += 1) {
          if (!UtilitarioGeral.validaCodigo(state.notaFiscal.itens[index].codigoProdutoDefinicao)) {
            indexSemCodigos.push(index);
          }
        }
        indexSemCodigos.forEach((index) => {
          state.notaFiscal.itens.splice(index, 1);
        });

        if (state.notaFiscal.itens.length > 0 && UtilitarioGeral.validaCodigo(state.notaFiscal.itens[0].impostos.codigoCfop)) {
          const cfop = await servicoCfop.obter(state.notaFiscal.itens[0].impostos.codigoCfop, state.notaFiscal.codigoEmpresa);
          if (cfop !== undefined) {
            state.notaFiscal.naturezaOperacao = UtilitarioGeral.limitarQuantidadeMaximaCaracteres(cfop.descricao, 60);
          }
        }
      }

      if (state.notaFiscal.totalImpostos.valorAproximadoTributosTotal > 0) {
        if (!state.notaFiscal.dadosAdicionais.includes('VALOR APROX. TRIBUTOS R$')) {
          const percentual = UtilitarioMatematico.valorDecimal2Casas((state.notaFiscal.totalImpostos.valorAproximadoTributosTotal / state.notaFiscal.totalNotaFiscal) * 100);
          state.notaFiscal.dadosAdicionais += `VALOR APROX. TRIBUTOS R$ ${UtilitarioMascara.mascararValor(state.notaFiscal.totalImpostos.valorAproximadoTributosTotal)} (${percentual} %) Fonte: IBPT`;
        }
      }

      if (state.notaFiscal.codigo > 0 && state.notaFiscal.codigo !== undefined) {
        apresentarBarraProgresso('Aguarde por favor...');
        if (state.atualizarApenasInformacoesFiscais) {
          retorno = await servicoNotaFiscal.alterarInformacoesFiscais(state.notaFiscal);
        } else {
          retorno = await servicoNotaFiscal.alterar(state.notaFiscal);
        }
      } else {
        state.incluindoCabecalhoNotaFiscal = true;
        retorno = await servicoNotaFiscal.incluirCabecalho(state.notaFiscal);
      }

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        excluirCacheRegistro();
        if (state.notaFiscal.codigo === 0) {
          await obterNotaFiscal(retorno.codigoRegistro, false);
          await obterRegimeTributario();
          state.incluindoCabecalhoNotaFiscal = false;
          ocultarBarraProgresso();
        } else {
          state.incluindoCabecalhoNotaFiscal = false;
          ocultarBarraProgresso();
          emit('confirmacao');
          apresentarMensagemSucesso(retorno.mensagem);
          modalBase.computedVisivel = false;
        }
      } else {
        state.incluindoCabecalhoNotaFiscal = false;
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        if (props.operacao.codigoRegistro > 0 && props.operacao.codigoRegistro !== undefined) {
          await obterNotaFiscal(props.operacao.codigoRegistro);
          await obterRegimeTributario();
        }

        telaBase.carregando = false;
        obterCacheRegistro();
        clearTimeout(debounceRenderizar);
        debounceRenderizar = setTimeout(async () => {
          focusElemento(1);
        }, 150);
      }
    });

    watch(async () => state.criacaoVendaAutomatico, async () => {
      if (state.criacaoVendaAutomatico) {
        await salvar();
      }
    });

    async function concluirEtapa() {
      Modal.confirm({
        title: `Deseja concluir a etapa da Nota Fiscal: ${state.notaFiscal.numeroNotaFiscal}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoNotaFiscal.concluirEtapa(state.notaFiscal.codigo);
          if (retorno.status !== 1) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemSucesso('Etapa concluída com sucesso!');
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    async function retornarEtapa() {
      Modal.confirm({
        title: `Deseja retornar a etapa anterior da Nota Fiscal: ${state.notaFiscal.numeroNotaFiscal}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoNotaFiscal.retornarEtapa(state.notaFiscal.codigo);
          if (retorno.status !== 1) {
            apresentarMensagemAlerta(retorno.mensagem);
          } else {
            apresentarMensagemSucesso('Nota Fiscal retornada com sucesso!');
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    function excluir() {
      Modal.confirm({
        title: 'Realmente deseja excluir a nota fiscal?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoNotaFiscal.excluir(state.notaFiscal.codigo);
          if (retorno.status !== 1) {
            apresentarMensagemErro(retorno.mensagem);
          } else {
            apresentarMensagemSucesso('Nota Fiscal excluída com sucesso!');
            emit('confirmacao');
            modalBase.computedVisivel = false;
          }
        },
      });
    }

    function obtemDescricaoIncidencia(identificador: string): string {
      if (identificador === 'OutrasDespesas') {
        return 'Outras Despesas';
      }

      return identificador;
    }

    async function atualizarAnexo(index: number) {
      if (state.notaFiscal.codigo === undefined || state.notaFiscal.codigo === 0) return;

      if (state.notaFiscal.anexos[index].codigo === undefined || state.notaFiscal.anexos[index].codigo === 0) {
        state.notaFiscal.anexos[index].codigoNotaFiscal = state.notaFiscal.codigo;
      }
    }

    async function excluirAnexo(codigoAnexo: number) {
      if (state.notaFiscal.codigo === undefined || state.notaFiscal.codigo === 0) return;

      await servicoNotaFiscal.excluirAnexo(codigoAnexo, state.notaFiscal.codigoEmpresa);
    }

    async function verificaFocoFormaPagamento() {
      if (state.notaFiscal.codigo === 0) {
        await salvar();
      }
    }

    function atualizarTotalDetalhesPagamento() {
      state.totalDetalhesPagamento = 0;
      state.notaFiscal.detalhesPagamento.forEach((d) => {
        state.totalDetalhesPagamento += d.valorTotal;
      });
    }

    async function atualizarDetalhePagamento(index: number) {
      if (state.notaFiscal.codigo === undefined || state.notaFiscal.codigo === 0) return;
      atualizarTotalDetalhesPagamento();

      if (state.notaFiscal.detalhesPagamento[index].codigo === undefined || state.notaFiscal.detalhesPagamento[index].codigo === 0) {
        state.notaFiscal.detalhesPagamento[index].codigoNotaFiscal = state.notaFiscal.codigo;
      }
    }

    function verificaSePodeExcluir():boolean {
      if (state.notaFiscal.origemNotaFiscal === EOrigemNotaFiscal.EmissaoNotaFiscalEletronica && state.notaFiscal.status === EStatusNotaFiscal.Pendente) {
        return true;
      }

      return false;
    }

    function verificaAlteracaoTipoEmissao() {
      if (state.tipoEmissaoAnterior === ETipoEmissao.EmissaoTerceiro && state.notaFiscal.tipoEmissao === ETipoEmissao.EmissaoPropria) {
        state.notaFiscal.numeroNotaFiscal = 0;
      }
    }

    function verificaEdicaoCampoEInformacaoFiscal():boolean {
      if (state.atualizarApenasInformacoesFiscais) {
        return false;
      }

      return !state.editavel;
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      requisicaoAutorizacaoBase,
      ETipoValorMovimentacao,
      ETipoMovimentoComercial,
      ETipoRelacaoPessoa,
      ETipoEmissao,
      EOrigemNotaFiscal,
      EStatusNotaFiscal,
      UtilitarioGeral,
      UtilitarioMascara,
      salvarCacheRegistro,
      storeSistema,
      servicoNotaFiscal,
      recuperarCacheRegistro,
      apresentarRetornoRequisicao,
      atualizarDescontoGeral,
      atualizarValoresTotais,
      buscarItensDadosAtualizados,
      obterNotaFiscal,
      salvar,
      concluirEtapa,
      retornarEtapa,
      excluir,
      obtemDescricaoIncidencia,
      atualizarAnexo,
      excluirAnexo,
      focusElemento,
      proximoElemento,
      elementoAnterior,
      verificaFocoFormaPagamento,
      atualizarInformacoesPessoa,
      atualizarInformacoesColaborador,
      adicionarTaxasPagamento,
      removerTaxasPagamento,
      atualizarDetalhePagamento,
      verificaSePodeExcluir,
      verificaAlteracaoTipoEmissao,
      verificaEdicaoCampoEInformacaoFiscal,
    };
  },
});
